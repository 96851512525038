import React, { useRef , useContext } from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import MahabadContext from './../../Context/MainContext';


const Index = () => {
  const context = useContext(MahabadContext)
    const captchaRef = useRef(null)
    function onChange(value) {
        context.setCaptchaVerifyed(value);
      }
    return (<ReCAPTCHA
        sitekey={process.env.REACT_APP_SITE_KEY}
        ref={captchaRef}
        hl="fa"
        onChange={onChange}
        />);
}
 
export default Index;