import React, { useState, useContext, useEffect } from 'react';
import Layout2 from "../Layout/Index"
import { StopOutlined, EyeOutlined } from '@ant-design/icons';
import { Layout, Breadcrumb, theme, Button, Row, Col, Input, message, Table, Tooltip } from 'antd';
import axios from 'axios';
import Config from '../../Config/config.json';
import MahabadContext from '../../Context/MainContext';
import { Grid, IconButton } from '@mui/material';
const { Content } = Layout;

const { Search } = Input;

const Index = () => {
  const [open, setOpen] = useState(false);
  const context = useContext(MahabadContext);
  const [size, setSize] = useState('large'); // default is 'middle'
  const [getTicket, setTicket] = useState(null); // default is 'middle'
  const [getText, setText] = useState(null); // default is 'middle'
  const [getStore, setStore] = useState(null); // default is 'middle'
  const [getAwnser, setAwnser] = useState(false); // default is 'middle'
  const [messageApi, contextHolder] = message.useMessage();
  const onSearch = (value) => handleSearchResturant(value);
  const handleSearchResturant = async (value) => {
    context.setTableLoading(true)
    await axios.get(`${Config.adminApi}/returants/getOne/${value}`, {
      headers: {
        "x-auth-token": JSON.parse(context.getCookie("adminToken"))
      }
    }).then(res => {
      context.setAllResturants(res.data)
      context.setTableLoading(false)
    }).catch((err) => {
      context.handleGetAllResturants()
      context.setTableLoading(false)
    })
  }
  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const handleReplayReport = async (value, count) => {
    let body = {
      "text": getText
    }
    await axios.post(`${Config.adminApi}/replayToReport/${getTicket._id}`, body, {
      headers: {
        "x-auth-token": JSON.parse(context.getCookie("adminToken"))
      }
    }).then(res => {
      context.setCookie("report", JSON.stringify(res.data))
      setTicket(res.data)
      messageApi.open({
        type: 'success',
        content: "پاسخ به شکایت با موفقیت افزوده شد",
      });
    }).catch(err => {
      messageApi.open({
        type: 'error',
        content: "مشکلی در ارسال پاسخ به شکایت وجود دارد",
      });
    })

  }
  const handleChangeStatus = async (id) => {
    await axios.get(`${Config.adminApi}/store/updateStatus/${id}/false`, {
      headers: {
        "x-auth-token": JSON.parse(context.getCookie("adminToken"))
      }
    }).then(res => {
      messageApi.open({
        type: 'success',
        content: "فروشگاه با موفقیت مسدود شد",
      });
    }).catch(err => {
      messageApi.open({
        type: 'error',
        content: "فروشگاه یافت نشد",
      });
    })

  }
  const handleGetStoreById = async (id) => {
    await axios.get(`${Config.adminApi}/store/ById/${id}`, {
      headers: {
        "x-auth-token": JSON.parse(context.getCookie("adminToken"))
      }
    }).then(res => {
      setStore(res.data)
    }).catch(err => {
      messageApi.open({
        type: 'success',
        content: "فروشگاه یافت نشد",
      });
    })

  }
  let columns = [
    {
      title: 'موضوع',
      dataIndex: 'title',
    },
    {
      title: 'پیام',
      dataIndex: 'text',
    },
    {
      title: 'نام فروشگاه',
      dataIndex: 'name',
    },
    {
      title: 'اولویت',
      dataIndex: 'priority',
    },
    {
      title: 'وضعیت',
      dataIndex: 'status',
    },
    {
      title: 'دپارتمان',
      dataIndex: 'departman',
    },
    {
      title: 'عملیات',
      key: 'storeId',
      dataIndex: 'storeId',
      value: 'storeId',
      fixed: 'right',
      width: 100,
      render: (storeId) => <Tooltip title="مسدود کردن فروشنده">
        <Button onClick={()=>{
          handleChangeStatus(storeId)
        }} style={{ borderRadius: "10px", padding: "7px 10px", display: "flex", alignItems: "center" }} type="primary" shape="circle" icon={<StopOutlined />} danger>مسدود کردن فروشنده</Button>
      </Tooltip>,
    },
  ];
  useEffect(() => {
    setTicket(JSON.parse(context.getCookie("report")))
    handleGetStoreById(JSON.parse(context.getCookie("report")).storeId)
  }, [])
  return (
    <Layout2>
      <div style={{ direction: "rtl" }}>{contextHolder}</div>
      <Row gutter={[16, 16]} justify="space-between">
        <Col xs={24} sm={7} md={10} lg={12} xl={14}>
          <Breadcrumb
            style={{
              margin: '16px 0',
              direction: "rtl"
            }}
          >
            <Breadcrumb.Item>خانه</Breadcrumb.Item>
            <Breadcrumb.Item>پیگیری شکایت</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      <Content
        style={{
          marginTop: '20px',
        }}
      >

        <div
          style={{
            marginBottom: 20
          }}
        >
          {getStore !== null ? (<section>
            <Grid container spacing={2} alignItems="start" justifyContent="flex-end">
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12} >
                <Table
                  columns={columns}
                  size="middle"
                  tableLayout="auto"
                  style={{ direction: "rtl" }}
                  scroll={{
                    x: 100
                  }}
                  loading={context.getTableLoading}
                  pagination={false}
                  dataSource={(getTicket !== null) ? ([getTicket]) : []}
                />
                <IconButton className="butSo" onClick={() => {
                  setAwnser(!getAwnser)
                }}>
                  <Grid container spacing={2} alignItems="center" justifyContent="space-between">
                    <Grid item xl={6} lg={6} md={6} sm={6} xs={6} className='d-flex text-right' style={{ textAlign: "right" }}>
                      پاسخ
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={6} xs={6} style={{ display: "flex", justifyContent: "end" }}>
                      {(typeof window !== "undefined") ? (<img style={{ height: "20px", width: "20px", textAlign: "left" }} src={window.origin + "/Admin/images/arrowButtom.svg"} />) : (null)}
                    </Grid>
                  </Grid>
                </IconButton>


                {getAwnser ? (<>
                  <Grid flexDirection={"row"} alignItems={"center"}>
                    <hr />
                    <Grid container spacing={2} alignItems="start" justifyContent="center">
                      {/* <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  ارسال تیکت
                </Grid> */}
                      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <Grid container spacing={2} alignItems="start" justifyContent="center">
                          {/* <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                          <p>نام</p>
                          <input onChange={(e) => {
                            setSubject(e.target.value)
                            validator.current.showMessageFor("نام کاربری");
                          }} className='ticketInput' value={JSON.parse(getCookie("store")).name} disabled style={{textAlign:"center"}} />
                        </Grid> */}

                          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                            <p>پیام شما</p>
                            <textarea onChange={(e) => {
                              setText(e.target.value)
                              context.validator.current.showMessageFor("پیام شما");
                            }} rows={5} className='awnserTextarea' placeholder='موضوع تیکت را وارد کنید' />
                            {context.validator.current.message("پیام شما", getText, 'required|min:3')}
                          </Grid>
                          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                            <Button onClick={() => {
                              handleReplayReport()
                            }} className='btnSic w-100 mb-3'>ارسال</Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </>) : (null)}


                {getTicket !== null ? (getTicket.replays.sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate)).map(item =>
                  <Grid container alignItems="start" justifyContent="flex-end">
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12} >
                      <Grid container alignItems="center" justifyContent="space-between" className='ViewTicketBox'>
                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} >
                          <Grid container alignItems="center" justifyContent="flex-end">
                            <Grid item xl={1} lg={1} md={2} sm={12} xs={12} >
                              {(item.role === "Admin") ? ((typeof window !== "undefined") ? (<img
                                style={{ width: "96px", height: "96px", borderRadius: "100px" }}
                                src={window.origin + "/Admin/images/Admin.svg"}
                                alt={"عکس ادمین"}
                              />) : (null)) : (!getStore.logo ? (null) : ((typeof window !== "undefined") ? (<img
                                style={{ width: "96px", height: "96px", borderRadius: "100px" }}
                                src={Config.adminPics + getStore.logo}
                                alt={getStore.name}
                              />) : (null)))}

                            </Grid>
                            <Grid item xl={1} lg={1} md={2} sm={6} xs={6} >
                              <p>{(item.role === "Admin") ? (<span>پشتیبانی</span>) : (<span>{item.name}</span>)}</p>
                              {(item.role === "Admin") ? (<span className="op1">اپراتور</span>) : (<span className="op">فروشنده</span>)}
                            </Grid>
                            <Grid item xl={10} lg={10} md={8} sm={6} xs={6} style={{ textAlign: "left" }}>
                              {new Date(item.createdDate).toLocaleTimeString('fa-IR')} | {new Date(item.createdDate).toLocaleDateString('fa-IR')}
                            </Grid>
                          </Grid>


                        </Grid>

                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} style={{ marginTop: "16px" }}>
                          <hr className='w-100' />
                        </Grid>
                        <pre style={{ whiteSpace: "normal", fontFamily: "iransans" }}>
                          {`${item.text}`}
                        </pre>
                      </Grid>
                    </Grid>
                  </Grid>
                )) : (null)}
                {getTicket !== null ? (<Grid container alignItems="start" justifyContent="flex-end">
                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12} >
                    <Grid container alignItems="center" justifyContent="space-between" className='ViewTicketBox'>
                      <Grid item xl={12} lg={12} md={12} sm={12} xs={12} >
                        <Grid container alignItems="start" justifyContent="flex-end">
                          <Grid item xl={1} lg={1} md={2} sm={12} xs={12} >
                            <img
                              style={{ width: "96px", height: "96px", borderRadius: "100px" }}
                              src={window.origin + "/Admin/images/Client.svg"}
                              alt={"عکس کاربر"}
                            />
                          </Grid>
                          <Grid item xl={1} lg={1} md={2} sm={6} xs={6} >
                            <p>{getTicket.name}</p>
                            <span className="op">کاربر</span>
                          </Grid>
                          <Grid item xl={10} lg={10} md={8} sm={6} xs={6} style={{ textAlign: "left" }}>
                            {new Date(getTicket.createdDate).toLocaleTimeString('fa-IR')} | {new Date(getTicket.createdDate).toLocaleDateString('fa-IR')}
                          </Grid>
                        </Grid>


                      </Grid>

                      <Grid item xl={12} lg={12} md={12} sm={12} xs={12} style={{ marginTop: "16px" }}>
                        <hr className='w-100' />
                      </Grid>

                      <pre style={{ whiteSpace: "normal", fontFamily: "iransans" }}>
                        {`${getTicket.text}`}
                      </pre>
                    </Grid>
                  </Grid>
                </Grid>) : (null)}




              </Grid>
            </Grid>

          </section>) : (null)}

        </div>
      </Content>
    </Layout2>

  );
};
export default Index;