import React, { useEffect, useContext, useState } from 'react';
import { EyeOutlined, DeleteOutlined, DownloadOutlined, ApartmentOutlined } from '@ant-design/icons';
import { Button, Tooltip, Modal, Typography, Table, message, Pagination, Image, Col, Row } from 'antd';
import axios from 'axios';
import Config from '../../Config/config.json';
import MahabadContext from '../../Context/MainContext';
import { Link } from 'react-router-dom';
const { Text } = Typography;




const Index = () => {
  const context = useContext(MahabadContext)
  const [getResturant, setResturant] = useState(null);
  const [messageApi, contextHolder] = message.useMessage();
  const [openEdite, setOpenEdite] = useState(false);
  const [openCategories, setOpenCategories] = useState(false);
  const [page, setPage] = useState(null);
  const showDrawerEdite = () => {
    setOpenEdite(true);
  };

  const onCloseEdite = () => {
    setOpenEdite(false);
  };
  const onShowCategories = () => {
    setOpenCategories(true);
  };

  const onCloseCategories = () => {
    setOpenCategories(false);
  };
  let columns = [
    {
      title: 'لوگو',
      key: 'photo',
      dataIndex: 'photo',
      value: 'photo',
      width: 100,
      render: (photo) => <Image style={{ width: "64px", height: "64px" }} src={Config.adminPics + photo} />,
    },
    {
      title: 'عنوان',
      dataIndex: 'h1',
    },
    {
      title: 'کلمه ی کلیدی برای سفارشات',
      dataIndex: 'searchedCategory',
    },
    {
      title: 'وضعیت',
      dataIndex: 'status',
      render: (status) => {
        if(status === "منتشر شده"){
          return(<span style={{ color: "green" }}>منتشر شده</span>)
        } else if (status === "منتشر نشده"){
          return(<span style={{ color: "red" }}>منتشر نشده</span>)
        }
      }
    },
    {
      title: 'مشاهده',
      key: '_id',
      dataIndex: '_id',
      value: '_id',
      fixed: 'right',
      width: 100,
      render: (id) => <Tooltip title="ویرایش صفحه">
        <Link to={`/edite?id=${id}`}>
        <Button type="primary" shape="circle" icon={<EyeOutlined />} />
        </Link>
        
      </Tooltip>,
    },
    {
      title: 'حذف',
      key: '_id',
      dataIndex: '_id',
      value: '_id',
      fixed: 'right',
      width: 100,
      render: (id) => <Tooltip title="حذف صفحه">
        <Button onClick={(e) => {
          setPage(context.getAllPages.find(item=> item._id === id))
          context.setDeleteAlert(true)
        }} type="primary" danger shape="circle" icon={<DeleteOutlined />} />
      </Tooltip>,
    },
  ];

  const handleDeletePage = async (id) => {
    context.setTableLoading(true)
    await axios.delete(`${Config.adminApi}/deletePage/${page._id}`, {
      headers: {
        "x-auth-token": JSON.parse(context.getCookie("adminToken"))
      }
    }).then(res => {
      context.setDeleteAlert(false)
      context.handleGetAllPages()
      messageApi.open({
        type: 'success',
        content: 'پیج با موفقیت حذف شد',
      });
      context.setTableLoading(false)
    }).catch((err) => {
      context.setDeleteAlert(false)
      context.setTableLoading(false)
      messageApi.open({
        type: 'error',
        content: 'پیج حذف نشد',
      });
    })
  }
  useEffect(() => {
    context.handleGetAllPages()
  }, [])
  const tableProps = {
    loading: context.getLoading
  };
  return (
    <>
      <Modal okType="danger" onOk={(e) => {
        handleDeletePage()
      }} okText="تایید" cancelText="انصراف" style={{ direction: "rtl" }} title="شما در حال حذف صفحه هستید" open={context.getDeleteAlert} onCancel={() => { context.setDeleteAlert(false) }}>
        <p>آیا مطمئنید؟</p>
      </Modal>
      <div style={{ direction: "rtl" }}>{contextHolder}</div>
      <Table
        columns={columns}
        size="middle"
        tableLayout="auto"
        style={{ direction: "rtl" }}
        scroll={{
          x: 100
        }}
        loading={context.getTableLoading}
        pagination={false}
        dataSource={(context.getAllPages !== null) ? context.getAllPages : []}
      />
      <div style={{ marginTop: "15px" }}><Pagination onChange={(e, v) => {
        context.handleGetAllPages(e)
      }} defaultCurrent={context.getPage}
        total={1000} /></div>
    </>
  );
};
export default Index;