import React, { useState,useContext } from 'react';
import Layout2 from "../Layout/Index"
import { PlusOutlined } from '@ant-design/icons';
import { Layout, Breadcrumb, theme, Button, Row, Col, Input } from 'antd';
import Table from "./Table";
import Add from "./Add";
import axios from 'axios';
import Config from '../../Config/config.json';
import MahabadContext from '../../Context/MainContext';
const { Content } = Layout;

const { Search } = Input;

const Index = () => {
    const [open, setOpen] = useState(false);
    const context = useContext(MahabadContext);
    const [size, setSize] = useState('large'); // default is 'middle'
    const onSearch = (value) => handleSearchResturant(value);
    const handleSearchResturant = async (value) => {
        context.setTableLoading(true)
        await axios.get(`${Config.adminApi}/returants/getOne/${value}`, {
            headers: {
                "x-auth-token": JSON.parse(context.getCookie("adminToken"))
            }
        }).then(res => {
                context.setAllResturants(res.data)
            context.setTableLoading(false)
        }).catch((err) => {
            context.handleGetAllResturants()
            context.setTableLoading(false)
        })
    }
    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };
    const {
        token: { colorBgContainer },
    } = theme.useToken();
    return (
        <Layout2>
                             <Row gutter={[16, 16]} justify="space-between">
                             <Col xs={24} sm={7} md={10} lg={12} xl={14}>
                             <Breadcrumb
                    style={{
                        margin: '16px 0',
                        direction: "rtl"
                    }}
                >
                    <Breadcrumb.Item>خانه</Breadcrumb.Item>
                    <Breadcrumb.Item>اشتراک ها</Breadcrumb.Item>
                </Breadcrumb>
                        </Col>

                        {/* <Col xs={24} sm={13} md={10} lg={9} xl={7}>
                            <div style={{ marginTop: "10px", marginBottom: "10px" }}>
                                <Search
                                    placeholder="مشخصات کسب و کار را وارد کنید"
                                    allowClear
                                    enterButton="جستجو"
                                    size="large"
                                    onSearch={onSearch}
                                />
                            </div>
                        </Col> */}
                        <Col xs={24} sm={4} md={4} lg={3} xl={3} style={{textAlign:"left"}}>
                            <div style={{ marginTop: "10px", marginBottom: "10px" }}>
                                <Button onClick={showDrawer} type="primary" icon={<PlusOutlined />} size={size}>
                                    افزودن
          </Button>
                            </div>
                        </Col>
                    </Row>
            <Content
                style={{
                    marginTop: '20px',
                }}
            >
     
                <div
                    style={{
                        marginBottom: 20
                    }}
                >

                    <Add open={open} onClose={onClose} />

                    <Table />
                </div>
            </Content>
        </Layout2>

    );
};
export default Index;