import React, { useEffect, useContext, useState } from 'react';
import { EyeOutlined, DeleteOutlined, DownloadOutlined, ApartmentOutlined } from '@ant-design/icons';
import { Button, Tooltip, Modal, Typography, Table, message, Pagination, Image, Col, Row } from 'antd';
import axios from 'axios';
import Config from '../../Config/config.json';
import MahabadContext from '../../Context/MainContext';
const { Text, Link } = Typography;




const Index = () => {
  const context = useContext(MahabadContext)
  const [getUser, setUser] = useState(null);
  const [messageApi, contextHolder] = message.useMessage();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleAcceptPayment = async (id) => {
    context.setTableLoading(true)
    await axios.get(`${Config.adminApi}/acceptPayment/${id}`, {
      headers: {
        "x-auth-token": JSON.parse(context.getCookie("adminToken"))
      }
    }).then(res => {
      context.setDeleteAlert(false)
      context.handleGetAllPayments()
      messageApi.open({
        type: 'success',
        content: "سفارش با موفقیت بروزرسانی شد",
      });
      context.setTableLoading(false)
    }).catch((err) => {
      context.setDeleteAlert(false)
      context.setTableLoading(false)
      messageApi.open({
        type: 'error',
        content: "وضعیت سفارش بروزرسانی نشد",
      });
    })
  }
  const handleCancelPayment = async (id) => {
    context.setTableLoading(true)
    await axios.get(`${Config.adminApi}/cancelPayment/${id}`, {
      headers: {
        "x-auth-token": JSON.parse(context.getCookie("adminToken"))
      }
    }).then(res => {
      context.setDeleteAlert(false)
      context.handleGetAllPayments()
      messageApi.open({
        type: 'success',
        content: "سفارش با موفقیت بروزرسانی شد",
      });
      context.setTableLoading(false)
    }).catch((err) => {
      context.setDeleteAlert(false)
      context.setTableLoading(false)
      messageApi.open({
        type: 'error',
        content: "وضعیت سفارش بروزرسانی نشد",
      });
    })
  }
  const handleGetUserById = async (id) => {
    context.setTableLoading(true)
    await axios.get(`${Config.adminApi}/users/byId/${id}`, {
      headers: {
        "x-auth-token": JSON.parse(context.getCookie("adminToken"))
      }
    }).then(res => {
      setUser(res.data)
    }).catch((err) => {
      setUser(null)
      messageApi.open({
        type: 'error',
        content: "کاربری یافت نشد",
      });
    })
  }
  useEffect(() => {
    context.handleGetAllPayments()
  }, [])
  const tableProps = {
    loading: context.getLoading
  };
  return (
    <>
      <div style={{ direction: "rtl" }}>{contextHolder}</div>
      <Modal style={{direction:"rtl"}} width={1000} title="مشخصات کاربر" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        {getUser !== null ? (<Row gutter={16} justify={"start"} align={"center"}>
          <Col style={{padding:"10px"}} xs={12} sm={12} md={12} lg={12} xl={12}>
            نام : {getUser.first_name}
          </Col>
          <Col style={{padding:"10px"}} xs={12} sm={12} md={12} lg={12} xl={12}>
            نام خانوادگی : {getUser.last_name}
          </Col>
          <Col style={{padding:"10px"}} xs={12} sm={12} md={12} lg={12} xl={12}>
            شماره موبایل : {getUser.phone}
          </Col>
          <Col style={{padding:"10px"}} xs={12} sm={12} md={12} lg={12} xl={12}>
           شماره ی ثابت : {getUser.phone_2}
          </Col>
          <Col style={{padding:"10px"}} xs={12} sm={12} md={12} lg={12} xl={12}>
           چنل : {getUser.channel}
          </Col>
          <Col style={{padding:"10px"}} xs={12} sm={12} md={12} lg={12} xl={12}>
          شرکت : {getUser.company}
          </Col>
          <Col style={{padding:"10px"}} xs={12} sm={12} md={12} lg={12} xl={12}>
          ایمیل : {getUser.email}
          </Col>
          <Col style={{padding:"10px"}} xs={12} sm={12} md={12} lg={12} xl={12}>
          شماره شبا : {getUser.shaba}
          </Col>
          <Col style={{padding:"10px"}} xs={12} sm={12} md={12} lg={12} xl={12}>
          صاحب شماره شبا : {getUser.payment_account_owner}
          </Col>
          <Col style={{padding:"10px"}} xs={12} sm={12} md={12} lg={12} xl={12}>
          سایت : {getUser.site}
          </Col>
          <Col style={{padding:"10px"}} xs={12} sm={12} md={12} lg={12} xl={12}>
          کد ملی : {getUser.code_meli}
          </Col>
          <Col style={{padding:"10px"}} xs={12} sm={12} md={12} lg={12} xl={12}>
          کد پستی : {getUser.postal_code}
          </Col>
          <Col style={{padding:"10px"}} xs={24} sm={24} md={24} lg={24} xl={24}>
          آدرس : {getUser.state},{getUser.city},{getUser.address}
          </Col>
          <Col style={{padding:"10px"}} xs={24} sm={24} md={12} lg={12} xl={12}>
          کارت ملی 
          <img style={{width:"100%",height:"300px"}} src={Config.adminPics + getUser.cart_meli_pic} />
          
          </Col>
          <Col style={{padding:"10px"}} xs={24} sm={24} md={12} lg={12} xl={12}>
          کارت بانکی 
          <img style={{width:"100%",height:"300px"}} src={Config.adminPics + getUser.cart_banki_pic} />
          </Col>
        </Row>) : null}
      </Modal>
      {(context.getAllPayments !== null) ? context.getAllPayments.map(item =>
        <Row className='nox' gutter={16} justify={"space-between"} align={"center"}>
          <Col xs={24} sm={24} md={3} lg={3} xl={3}>
            <img style={{ width: "128px", height: "128px" }} src={Config.adminPics + item.servicePhoto} />
          </Col>
          <Col xs={24} sm={24} md={3} lg={3} xl={3}>
            <p>آدرس : {item.site}</p>
            <p>تیتر اول : {item.titr_1}</p>
            <p>تیتر دوم : {item.titr_2}</p>
            <p>تیتر سوم : {item.titr_3}</p>
          </Col>
          <Col xs={24} sm={24} md={3} lg={3} xl={3}>
            <p>توضیحات اول : {item.desc_1}</p>
            <p>توضیحات دوم : {item.desc_2}</p>
            <p>کلمات کلیدی : {item.keywords.map(item => ` | ${item.label}`)}</p>
          </Col>
          <Col xs={24} sm={24} md={5} lg={5} xl={5}>
            <p>نام سرویس : {item.serviceName}</p>
            <p>دسته بندی سرویس : {item.serviceCategory}</p>
            <p>توضیحات سرویس : {item.serviceDesc}</p>
          </Col>
          <Col xs={24} sm={24} md={5} lg={5} xl={5}>
            <p>مبلغ : {item.amount}</p>
            <p>نوع پرداخت : {item.payment_type}</p>
            <p>وضعیت پرداخت : {item.success ? (<span style={{ color: "green" }}>پرداخت شده</span>) : (<span style={{ color: "red" }}>پرداخت نشده</span>)}</p>
            <p>وضعیت سفارش : {item.status === "تکمیل شده" ? (<span style={{ color: "green" }}>{item.status}</span>) : (<span style={{ color: "red" }}>{item.status}</span>)}</p>
            <p>تاریخ سفارش : {new Date(item.createdDate).toLocaleTimeString('fa-IR')} | {new Date(item.createdDate).toLocaleDateString('fa-IR')}</p>
          </Col>
          <Col xs={24} sm={24} md={3} lg={3} xl={3}>
          <Row>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}><button onClick={(e) => {
              handleGetUserById(item.userId)
              showModal()
            }} className='btnS3'>مشاهده ی کاربر</button></Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}><button onClick={() => {
              handleAcceptPayment(item._id)
            }} className='btnS1' style={{ marginTop: "16px" }}>تکمیل سفارش</button></Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}><button onClick={() => {
              handleCancelPayment(item._id)
            }} className='btnS2' style={{ marginTop: "16px" }}>کنسل کردن سفارش</button></Col>
            
            
            
          </Row>
          </Col>
        </Row>
      ) : null}
      <div style={{ marginTop: "15px" }}><Pagination onChange={(e, v) => {
        context.handleGetAllPayments(e)
      }} defaultCurrent={context.getPage}
        total={1000} /></div>
    </>
  );
};
export default Index;