import React, { useState, useContext, useEffect, useRef } from 'react';
import Layout2 from "../Layout/Index"
import { PlusOutlined, UploadOutlined, FontColorsOutlined } from '@ant-design/icons';
import { Layout, Breadcrumb, theme, Button, Row, Col, Input, message, Upload, Select } from 'antd';
import axios from 'axios';
import Config from '../../Config/config.json';
import MahabadContext from '../../Context/MainContext';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2';
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Box from '@mui/material/Box';

import Paper from '@mui/material/Paper';
const { Content } = Layout;

const { Search } = Input;
function uploadAdapter(loader) {
    return {
        upload: () => {
            return new Promise(async (resolve, reject) => {
                try {
                    const file = await loader.file;
                    const response = await axios.request({
                        method: "POST",
                        url: `${Config.adminApi}/addPhoto`,
                        data: {
                            "logo": file
                        },
                        headers: {
                            "Content-Type": "multipart/form-data"
                        }
                    });
                    resolve({
                        default: `${Config.adminPics}${response.data}`
                    });
                    console.log(response.data)
                } catch (error) {
                    reject("Hello");
                }
            });
        },
        abort: () => { }
    };

}
function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
        return uploadAdapter(loader);
    };
}
const Index = () => {
    const [open, setOpen] = useState(false);
    const context = useContext(MahabadContext);
    const [editor, setEditor] = useState();
    const [size, setSize] = useState('large'); // default is 'middle'
    const [getPhotoBg, setPhotoBg] = useState("");
    const [getH1, setH1] = useState("");
    const [messageApi, contextHolder] = message.useMessage();
    const [getLoading, setLoading] = useState(false);
    const [getDesc, setDesc] = useState("");
    const [getStatus, setStatus] = useState("منتشر نشده");
    const [getUrl, setUrl] = useState("");
    const [getBody, setBody] = useState("");
    const [getSelectedCategory, setSelectedCategory] = useState("null");
    const [getProperties, setProperties] = useState([])
    const [getPropertyName, setPropertyName] = useState("")
    const [getPropertyDesc, setPropertyDesc] = useState("")
    const [getSearchedCategory, setSearchedCategory] = useState("");
    const fileList = [
    ];
    const onSearch = (value) => handleSearchResturant(value);
    const handleSearchResturant = async (value) => {
        context.setTableLoading(true)
        await axios.get(`${Config.adminApi}/returants/getOne/${value}`, {
            headers: {
                "x-auth-token": JSON.parse(context.getCookie("adminToken"))
            }
        }).then(res => {
            context.setAllResturants(res.data)
            context.setTableLoading(false)
        }).catch((err) => {
            context.handleGetAllResturants()
            context.setTableLoading(false)
        })
    }
    const handleAddPage = async () => {
        setLoading(true);
        context.setTableLoading(true)
        let body = {
            'h1': getH1,
            'description': getDesc,
            'searchedCategory': getSearchedCategory,
            'url': getUrl,
            'photo': getPhotoBg,
            'body': editor,
            'status': getStatus,
            'information': getProperties,
            'category': getSelectedCategory,
        }
        await axios.post(`${Config.adminApi}/page`, body, {
            headers: {
                "x-auth-token": JSON.parse(context.getCookie("adminToken"))
            }
        }).then(res => {
            context.handleGetAllResturants()
            setLoading(false);
            context.setTableLoading(false)
            onClose()
            messageApi.open({
                type: 'success',
                content: 'پیج با موفقیت افزوده شد',
            });
        }).catch((err) => {
            setLoading(false);
            context.setTableLoading(false)
            onClose()
            messageApi.open({
                type: 'error',
                content: 'پیج اضافه نشد',
            });
        })
    }
    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };
    const {
        token: { colorBgContainer },
    } = theme.useToken();
useEffect(()=>{
    context.handleGetAllCategories()
},[])
    return (
        <Layout2>
            {contextHolder}
            <Row gutter={[16, 16]} justify="space-between">
                <Col xs={24} sm={7} md={10} lg={12} xl={14}>
                    <Breadcrumb
                        style={{
                            margin: '16px 0',
                            direction: "rtl"
                        }}
                    >
                        <Breadcrumb.Item>خانه</Breadcrumb.Item>
                        <Breadcrumb.Item>افزودن صفحه</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>

                {/* <Col xs={24} sm={13} md={10} lg={9} xl={7}>
                            <div style={{ marginTop: "10px", marginBottom: "10px" }}>
                                <Search
                                    placeholder="مشخصات کسب و کار را وارد کنید"
                                    allowClear
                                    enterButton="جستجو"
                                    size="large"
                                    onSearch={onSearch}
                                />
                            </div>
                        </Col> */}
            </Row>
            <Content
                style={{
                    marginTop: '20px',
                }}
            >
                <Container maxWidth="xl">
                    <Box sx={{ flexGrow: 1 }}>
                        <Grid container spacing={2}>
                            <Grid xs={12}>
                                <p>URL پیج</p>
                                <Input onChange={(e) => {
                                    setUrl(e.target.value.replace(/\s/g, '_'))
                                    context.validator.current.showMessageFor("url");
                                }} prefix={<FontColorsOutlined />} value={getUrl} status={(getUrl.length < 3) ? ("error") : (null)} placeholder="URL پیج را وارد کنید" />
                            </Grid>
                            <Grid xs={4}>
                                <p>عنوان پیج</p>
                                <Input onChange={(e) => {
                                    setH1(e.target.value)
                                    context.validator.current.showMessageFor("name");
                                }} prefix={<FontColorsOutlined />} status={(getH1.length < 3) ? ("error") : (null)} placeholder="عنوان پیج را وارد کنید" />
                            </Grid>
                            <Grid xs={4}>
                                <p>توضیحات پیج</p>
                                <Input onChange={(e) => {
                                    setDesc(e.target.value)
                                    context.validator.current.showMessageFor("name");
                                }} prefix={<FontColorsOutlined />} status={(getDesc.length < 3) ? ("error") : (null)} placeholder="توضیحات پیج را وارد کنید" />
                            </Grid>
                            <Grid xs={4}>
                            <p>کلمه ی کلیدی برای سرویس های قابل فروش</p>
                                <Input onChange={(e) => {
                                    setSearchedCategory(e.target.value)
                                    context.validator.current.showMessageFor("name");
                                }} prefix={<FontColorsOutlined />} status={(getSearchedCategory.length < 3) ? ("error") : (null)} placeholder="کلمه ی کلیدی سرویس ها را وارد کنید" />
                            </Grid>
                            <Grid xs={8}>
                                <CKEditor
                                    config={{
                                        // @ts-ignore
                                        extraPlugins: [uploadPlugin]
                                    }}
                                    editor={ClassicEditor}
                                    onReady={(editor) => { }}
                                    onBlur={(event, editor) => { }}
                                    onFocus={(event, editor) => { }}
                                    onChange={(event, editor) => {
                                        setEditor(editor.getData());
                                        console.log(editor.getData())
                                    }}
                                    data={editor}
                                />
                            </Grid>
                            <Grid xs={4}>
                                            <p>دسته بندی</p>
                                <Select

                                    defaultValue={"انتخاب کنید"}
                                    style={{ width: "100%" }}
                                    onChange={(e, v) => {
                                        if (v.value === "null") {
                                            setSelectedCategory("null")
                                        } else {
                                            setSelectedCategory(v.label)
                                        }
                                    }}
                                    options={[{ value: "null", label: "انتخاب کنید" }, ...context.getAllCategories.map(item=>({ value: item.name, label: item.name }))]}
                                />
                                    
                                    <p>وضعیت انتشار</p>
                                <Select

                                    defaultValue={"انتخاب کنید"}
                                    style={{ width: "100%" }}
                                    onChange={(e, v) => {
                                        if (v.value === "null") {
                                            setStatus("null")
                                        } else {
                                            setStatus(v.label)
                                        }
                                    }}
                                    options={[{ value: "null", label: "انتخاب کنید" }, { value: "منتشر نشده", label: "منتشر نشده" }, { value: "منتشر شده", label: "منتشر شده" }]}
                                />
                                <p>عکس بالای صفحه</p>
                                <Upload
                                    action={`${Config.adminApi}/addPhoto`}
                                    listType="picture"
                                    defaultFileList={[...fileList]}
                                    className="upload-list-inline"
                                    name="logo"
                                    headers={{
                                        "x-auth-token": JSON.parse(context.getCookie("adminToken"))
                                    }}
                                    onChange={(e) => { setPhotoBg(e.file.response) }}
                                >
                                    <Button icon={<UploadOutlined />}>عکس هدر</Button>
                                </Upload>
                            </Grid>
                            <Grid xs={12} container spacing={2} justifyContent={"center"} alignItems={"center"}>
                                <Grid xs={5}>
                                <p>نام ویژگی</p>
                                <Input onChange={(e) => {
                                    setPropertyName(e.target.value)
                                    context.validator.current.showMessageFor("name");
                                }} prefix={<FontColorsOutlined />} status={(getPropertyName.length < 3) ? ("error") : (null)} placeholder="نام ویژگی را وارد کنید" />
                                </Grid>
                                <Grid xs={5}>
                                <p>توضیحات ویژگی</p>
                                <Input onChange={(e) => {
                                    setPropertyDesc(e.target.value)
                                    context.validator.current.showMessageFor("name");
                                }} prefix={<FontColorsOutlined />} status={(getPropertyDesc.length < 3) ? ("error") : (null)} placeholder="توضیحات ویژگی را وارد کنید" />
                                </Grid>
                                <Grid xs={2} alignSelf={"end"}>
                                <Button style={{width:"100%",backgroundColor:"green"}} onClick={()=>{
                                        setProperties([...getProperties,{name : getPropertyName ,description : getPropertyDesc}])
                                    }} type="primary" icon={<PlusOutlined />} size={"medium"}>
                                   افزودن ویژگی
                                    </Button>
                                </Grid>
                            </Grid>
                                {(getProperties.length >= 1) ? (getProperties.map(item=>(<Grid className="property" xs={12} container spacing={2} justifyContent={"center"} alignItems={"center"}>
                                <Grid xs={5}>
                                <p>نام ویژگی</p>
                                <Input onChange={(e) => {
                                    setPropertyName(e.target.value)
                                    context.validator.current.showMessageFor("name");
                                }} prefix={<FontColorsOutlined />} disabled value={item.name} />
                                </Grid>
                                <Grid xs={5}>
                                <p>توضیحات ویژگی</p>
                                <Input onChange={(e) => {
                                    setPropertyDesc(e.target.value)
                                    context.validator.current.showMessageFor("name");
                                }} prefix={<FontColorsOutlined />} disabled value={item.description} />
                                </Grid>
                                <Grid xs={2} alignSelf={"end"}>
                                <Button style={{width:"100%",backgroundColor:"red"}} onClick={()=>{
                                        setProperties((getProperties) => getProperties.filter(pro => pro.name !== item.name));
                                    }} type="primary" icon={<PlusOutlined />} size={"medium"}>
                                 حذف ویژگی
                                    </Button>
                                </Grid>
                            </Grid>))) : (null)}
                            
                            <Grid xs={2}>
                                <div style={{ marginTop: "10px", marginBottom: "10px" }}>
                                    <Button onClick={handleAddPage} type="primary" icon={<PlusOutlined />} size={size}>
                                        افزودن
                                    </Button>
                                </div>
                            </Grid>
                        </Grid>
                    </Box>

                </Container>
            </Content>
        </Layout2>

    );
};
export default Index;