import React, { useEffect, useContext, useState } from 'react';
import { ClockCircleOutlined, UploadOutlined,FontColorsOutlined,MobileOutlined,UserOutlined,LockOutlined } from '@ant-design/icons';
import { Button, Col, Upload, Drawer, Form, Input , message, Select, Space } from 'antd';
import Config from '../../Config/config.json';
import axios from 'axios';
import MahabadContext from '../../Context/MainContext';
const { Option } = Select;
let fileList = [
];


const Index = ({ open, onClose , category }) => {
  const context = useContext(MahabadContext)
  const [messageApi, contextHolder] = message.useMessage();
  const [ getLoading , setLoading] = useState(false);
  const [ getPhoto , setPhoto] = useState("");
  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };
  const handleEditeCategory = async () => {
    setLoading(true);
    context.setTableLoading(true)
    let body = {
      'name':category.name
    }
    await axios.put(`${Config.adminApi}/category/${category._id}`,body, {
      headers: {
          "x-auth-token": JSON.parse(context.getCookie("adminToken"))
      }
  }).then(res => {
      context.handleGetAllCategories()
      setLoading(false);
      context.setTableLoading(false)
      onClose()
      messageApi.open({
        type: 'success',
        content: 'دسته بندی با موفقیت ویرایش شد',
      });
    }).catch((err)=>{
      setLoading(false);
      context.setTableLoading(false)
      onClose()
      messageApi.open({
        type: 'error',
        content: 'دسته بندی ویرایش نشد',
      });
    })
  }

  return (
    <>
    {contextHolder}
      <Drawer
        title="ویرایش دسته بندی"
        onClose={onClose}
        open={open}
        bodyStyle={{
          paddingBottom: 80,
          direction: "rtl"
        }}
        style={{ direction: "rtl" }}
        extra={
          <Space>
            <Button onClick={onClose} danger>انصراف</Button>
            <Button onClick={handleEditeCategory} type="primary">
              {(getLoading) ? ("در حال ویرایش") : ("ویرایش")}
            </Button>
          </Space>
        }
      >
        <Form layout="vertical" hideRequiredMark>
          <p>نام دسته بندی</p>
          <Input onChange={(e) => {
            category.name = e.target.value
          }} prefix={<FontColorsOutlined /> } placeholder={(category) ? (category.name) : ("نام دسته بندی را وارد کنید")} />
                    {/* <p>دسته بندی پدر</p>
          <Select
          
      defaultValue={(category.topCategory === "null") ? ("ندارد") : (category.topCategory)}
      style={{ width: "100%" }}
      onChange={(e,v)=>{
        if(v.value === "null"){
          category.topCategory = "null"
        }else{
          category.topCategory = v.label
        }
        console.log(category)
      }}
      options={(context.getAllCategoriesForSearch !== null) ? ([{"value" : "null"  , "label" : "انتخاب کنید"},...context.getAllCategoriesForSearch.map(item=> ({"value" : item._id  , "label" : item.name}))]) : []}
    />
          <p>عکس دسته بندی</p>
          <Upload
            action={`${Config.adminApi}/category/addPhoto`}
            listType="picture"
            defaultFileList={[...fileList]}
            className="upload-list-inline"
            name="categoryPhoto"
            headers={{
              "x-auth-token": JSON.parse(context.getCookie("adminToken"))
            }}
            onChange={(e) => {
               category.photo = e.file.response
              }}
          >
            <Button icon={<UploadOutlined />}>عکس دسته بندی</Button>
          </Upload> */}
        </Form>
      </Drawer>
    </>
  );
};
export default Index;