import React, { useState, useRef } from 'react';
import axios from "axios";
import MahabadContext from "./Context/MainContext";
import swal from 'sweetalert';
import SimpleReactValidator from 'simple-react-validator';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';
import Config from './Config/config.json';


let ToastifyOptions = {
    position: "bottom-left",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
}

const GlobalState = props => {
    const [, forceUpdate] = useState();
    SimpleReactValidator.addLocale('fa', {
        accepted: ':attribute مورد قبول قرار نگرفت',
        after: ':attribute باید تاریخی بعد از :date باشد.',
        after_or_equal: ':attribute باید تاریخی برابر یا بعد از :date باشد.',
        alpha: ":attribute باید شامل حروف انگلیسی باشد.",
        alpha_space: ":attribute باید شامل حروف انگلیسی و فاصله باشد.",
        alpha_num: ":attribute باید شامل اعداد و حروف انگلیسی باشد.",
        alpha_num_space: ":attribute باید شامل اعداد و حروف انگلیسی و فاصله باشد.",
        alpha_num_dash: ":attribute باید شامل اعداد و حروف انگلیسی و خط تیره.",
        alpha_num_dash_space: ":attribute باید شامل اعداد و حروف انگلیسی، خط تیره و فاصله باشد.",
        array: ":attribute باید یک آرایه باشد.",
        before: ":attribute باید قبل از :date باشد.",
        before_or_equal: ":attribute باید قبل یا برابر با :date باشد.",
        between: ":attribute باید بین :min و :max:type باشد.",
        boolean: ":attribute باید یک بول باشد.",
        card_exp: ":attribute باید یک تاریخ انقضا معتبر باشد.",
        card_num: ":attribute باید یک شماره کارت اعتباری معتبر باشد.",
        currency: ":attribute باید یک ارز معتبر باشد.",
        date: ":attribute باید تاریخ باشد.",
        date_equals: ":attribute باید در :date بازه باشد.",
        email: ":attribute بایدآدرس ایمیل معتبر باشد.",
        in: ":attribute باید یکی از :values باشد.",
        integer: ":attribute باید یک عدد صحیح باشد.",
        max: ":attribute باید کمتر از :max:type باشد.",
        min: ":attribute باید بیشتر از :min:type باشد.",
        not_in: ":attribute نباید مشابه با :values باشد.",
        not_regex: ":attribute نباید با الگوی مورد نیاز مطابقت داشته باشد.",
        numeric: ":attribute باید عدد باشد.",
        phone: ":attribute باید یک شماره تلفن معتبر باشد.",
        regex: ":attribute باید با الگوی مورد نیاز مطابقت داشته باشد.",
        required: "وارد کردن :attribute الزامی است.",
        size: ":attribute باید تبدیل شود به size:type:.",
        string: ":attribute باید یک رشته باشد.",
        typeof: ":attribute نوع صحیح :type نوع نیست.",
        url: ":attribute باید یک آدرس اینترنتی باشد.",
    });
    const validator = useRef(
        new SimpleReactValidator({
            locale: 'fa',
            element: message => <div style={{ color: "#ff9e9e", fontSize: "14px" }}>{message}</div>
        })
    );
    function setCookie(cname, cvalue, exdays) {
        var d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        var expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }
    function getCookie(cname) {
        var name = cname + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }
    const [getLoading, setLoading] = useState(false);
    const [getTableLoading, setTableLoading] = useState(false);
    const [collapsed, setCollapsed] = useState(true);
    const [getAllResturants, setAllResturants] = useState(null);
    const [getAllQuestions, setAllQuestions] = useState(null);
    const [getResturantId, setResturantId] = useState(null);
    const [getAllContacts, setAllContacts] = useState(null);
    const [getDeleteAlert, setDeleteAlert] = useState(false);
    const [getAllUsers, setAllUsers] = useState(null);
    const [getAllPayments, setAllPayments] = useState(null);
    const [getAllCashouts, setAllCashouts] = useState(null);
    const [getAllDiscounts, setAllDiscounts] = useState(null);
    const [getAdminUsername, setAdminUsername] = useState(null);
    const [getSettings, setSettings] = useState(null);
    const [getAdmin, setAdmin] = useState(null);
    const [getAdminPassword, setAdminPassword] = useState(null);
    const [getPage, setPage] = useState(1);
    const [getRestaurant, setRestaurant] = useState(null);
    const [getAllProductsPlan, setAllProductsPlan] = useState(null);
    const [getMatrixBetweenPoints, setMatrixBetweenPoints] = useState(null);
    const [getAllCategories, setAllCategories] = useState([]);
    const [getAllCategoriesForSearch, setAllCategoriesForSearch] = useState(null);
    const [getAllProducts, setAllProducts] = useState(null);
    const [getAllPages, setAllPages] = useState(null);
    const [getAllTickets, setAllTickets] = useState(null);
    const [getAllDeposits, setAllDeposits] = useState(null);
    const [getAllReports, setAllReports] = useState(null);
    const [getCaptchaVerifyed, setCaptchaVerifyed] = useState(null);
    const [getServices, setServices] = useState(null);
    const [getUser, setUser] = useState(null);
    const [getChoosenLocation, setChoosenLocation] = useState({"lng" : 45.72125077072033, "lat" : 36.764990885596916});
    // const handleGetIncome = async () => {
    //     let ss = 0
    //     await axios.get(`${Config.adminApi}/getIncome`).then(res => {
    //       console.log(res.data)

    //     })
    //   }

    const handleGetAllTickets = async (value, count) => {
        setTableLoading(true)
        if (!value) {
            value = 1
        }
        if (!count) {
            count = 10
        }
        await axios.get(`${Config.adminApi}/tickets/${value}/${count}`, {
            headers: {
                "x-auth-token": JSON.parse(getCookie("adminToken"))
            }
        }).then(res => {
            setAllTickets(res.data)
            setPage(value)
            setTableLoading(false)
        }).catch((err) => {
            setTableLoading(false)
            setAllTickets(null)
        })
    }
    const handleGetAllUsers = async (value, count) => {
        if (!value) {
            value = 1
        }
        if (!count) {
            count = 10
        }
        await axios.get(`${Config.adminApi}/users/${value}/${count}`, {
            headers: {
                "x-auth-token": JSON.parse(getCookie("adminToken"))
            }
        }).then(res => {
            setAllUsers(res.data)
            setPage(value)
        }).catch((ex)=>{
            setAllUsers(null)
        })
    }
    const handleGetAllCategories = async (value, count) => {
        setTableLoading(true)
        if (!value) {
            value = 1
        }
        if (!count) {
            count = 10
        }
        await axios.get(`${Config.adminApi}/category/${value}/${count}`, {
            headers: {
                "x-auth-token": JSON.parse(getCookie("adminToken"))
            }
        }).then(res => {
            setAllCategories(res.data)
            setTableLoading(false)
            setPage(value)
        }).catch(ex=>{
            setAllCategories(null)
        })
    }
    const handleGetAllServices = async (value, count) => {
        setTableLoading(true)
        if (!value) {
            value = 1
        }
        if (!count) {
            count = 100
        }
        await axios.get(`${Config.adminApi}/services/${value}/${count}`, {
            headers: {
                "x-auth-token": JSON.parse(getCookie("adminToken"))
            }
        }).then(res => {
            setServices(res.data)
            setTableLoading(false)
            setPage(value)
        }).catch(ex=>{
            setServices(null)
        })
    }
    
    const handleGetAllCategoriesForEdite = async (value, count) => {
        setTableLoading(true)
        if (!value) {
            value = 1
        }
        if (!count) {
            count = 100
        }
        await axios.get(`${Config.adminApi}/category/${value}/${count}`, {
            headers: {
                "x-auth-token": JSON.parse(getCookie("adminToken"))
            }
        }).then(res => {
            setAllCategories(res.data)
            setTableLoading(false)
            setPage(value)
        }).catch(ex=>{
            setAllCategories(null)
        })
    }
    const handleGetAllProducts = async (value, count) => {
        setTableLoading(true)
        if (!value) {
            value = 1
        }
        if (!count) {
            count = 10
        }
        await axios.get(`${Config.restaurantApi}/product/${value}/${count}`, {
            headers: {
                "x-auth-token": JSON.parse(getCookie("restaurantToken"))
            }
        }).then(res => {
            setAllProducts(res.data)
            setTableLoading(false)
            setPage(value)
        })
    }
    const handleGetAllProductsByParent = async (name , value, count , status) => {
        setTableLoading(true)
        if (!value) {
            value = 1
        }
        if (!count) {
            count = 10
        }
        await axios.get(`${Config.restaurantApi}/products/byCategory/${name}/${value}/${count}/${status}`, {
            headers: {
                "x-auth-token": JSON.parse(getCookie("restaurantToken"))
            }
        }).then(res => {
            setAllProducts(res.data)
            setTableLoading(false)
            setPage(value)
        })
    }
    const handleGetAllPayments = async (page, count) => {
        if (!page) {
            page = 1
        }
        if (!count) {
            count = 10
        }
        setTableLoading(true)
        await axios.get(`${Config.adminApi}/payments/${page}/${count}`, {
            headers: {
                "x-auth-token": JSON.parse(getCookie("adminToken"))
            }
        }).then(res => {
            setAllPayments(res.data)
            setPage(page)
            setTableLoading(false)
        }).catch((err) => {
            setPage(page)
            setTableLoading(false)
        })
    }
    const handleGetAllCashouts = async (page, count) => {
        if (!page) {
            page = 1
        }
        if (!count) {
            count = 10
        }
        setTableLoading(true)
        await axios.get(`${Config.adminApi}/getCashouts/${page}/${count}`, {
            headers: {
                "x-auth-token": JSON.parse(getCookie("adminToken"))
            }
        }).then(res => {
            setAllCashouts(res.data)
            setPage(page)
            setTableLoading(false)
        }).catch((err) => {
            setPage(page)
            setTableLoading(false)
        })
    }
    const handleGetAllDeposits = async (page, count) => {
        if (!page) {
            page = 1
        }
        if (!count) {
            count = 10
        }
        setTableLoading(true)
        await axios.get(`${Config.adminApi}/getDeposits/${page}/${count}`, {
            headers: {
                "x-auth-token": JSON.parse(getCookie("adminToken"))
            }
        }).then(res => {
            setAllDeposits(res.data)
            setPage(page)
            setTableLoading(false)
        }).catch((err) => {
            setPage(page)
            setTableLoading(false)
        })
    }
    const handleRestaurantGetAllCashouts = async (page, count) => {
        if (!page) {
            page = 1
        }
        if (!count) {
            count = 10
        }
        setTableLoading(true)
        await axios.get(`${Config.restaurantApi}/cashouts/${page}/${count}`, {
            headers: {
                "x-auth-token": JSON.parse(getCookie("restaurantToken"))
            }
        }).then(res => {
            setAllCashouts(res.data)
            setPage(page)
            setTableLoading(false)
        }).catch((err) => {
            setPage(page)
            setTableLoading(false)
        })
    }
    const handleGetAllDiscounts = async (page, count) => {
        if (!page) {
            page = 1
        }
        if (!count) {
            count = 10
        }
        setTableLoading(true)
        await axios.get(`${Config.adminApi}/discount/${page}/${count}`, {
            headers: {
                "x-auth-token": JSON.parse(getCookie("adminToken"))
            }
        }).then(res => {
            setAllDiscounts(res.data)
            setPage(page)
            setTableLoading(false)
        }).catch((err) => {
            setPage(page)
            setTableLoading(false)
        })
    }
    const _isAdmin = async () => {
        if (getCookie("adminToken") && JSON.parse(getCookie("adminToken")) != null) {
            return(true)
        } else {
            return(false)
        }
    }
    const _isRestaurant = async () => {
        if (getCookie("restaurantToken") && JSON.parse(getCookie("restaurantToken")) != null) {
            return(true)
        } else {
            return(false)
        }
    }
    const handleLoginAdmin = async () => {
        if(getCaptchaVerifyed == null){
            if (validator.current.fieldValid("نام کاربری") && validator.current.fieldValid("رمز عبور")) {
                let body = {
                    "username": getAdminUsername,
                    "password": getAdminPassword
                }
                await axios.post(`${Config.adminApi}/login`, body).then(res => {
                    setCookie("adminToken", JSON.stringify(res.headers["x-auth-token"]), 365)
                    setCookie("admin", JSON.stringify(res.data), 365)
                    toast.success('ورود با موفقیت انجام شد', ToastifyOptions);
                    window.location.assign('/services')
                }).catch(err => {
                    swal({
                        title: "ناموفق",
                        text: err.response.data.message,
                        icon: "error",
                        button: "بستن"
                      });
                })
            } else {
                validator.current.showMessages();
                forceUpdate(1);
            }

        }else{
            swal({
                title: "ناموفق",
                text: "گزینه ی من ربات نیستم را بدرستی ارسال کنید",
                icon: "error",
                button: "بستن"
              });
        }
    }
    const handleLogOutAdmin = async () => {
        setCookie("adminToken", null, 365)
        window.location.assign("/admin/login")
    }
    const handleRestaurantLogin = async () => {
        if(getCaptchaVerifyed == null){
            if (validator.current.fieldValid("نام کاربری") && validator.current.fieldValid("رمز عبور")) {
                setLoading(true)
                let body = {
                    "username": getAdminUsername,
                    "password": getAdminPassword
                }
                await axios.post(`${Config.restaurantApi}/login`, body).then(res => {
                    setCookie("restaurantToken", JSON.stringify(res.headers["x-auth-token"]), 365)
                    toast.success('ورود با موفقیت انجام شد', ToastifyOptions);
                    window.location.assign('/restaurant/orders')
                    setLoading(false)
                }).catch(err => {
                    swal({
                        title: "ناموفق",
                        text: err.response.data.message,
                        icon: "error",
                        button: "بستن"
                      });
                      setLoading(false)
                })
            } else {
                validator.current.showMessages();
                forceUpdate(1);
            }
        }else{
            swal({
                title: "ناموفق",
                text: "گزینه ی من ربات نیستم را بدرستی ارسال کنید",
                icon: "error",
                button: "بستن"
              });
        }
        
    }

    const handleGetRestaurant = () => {
        axios.get(`${Config.restaurantApi}/getRestaurant`, {
            headers: {
                "x-auth-token": JSON.parse(getCookie("restaurantToken"))
            }
        }).then((e) => {
            setRestaurant(e.data)
        });
    }
    const handleFindAllPayments = (value,count) => {
        if (!value) {
            value = 1;
        }
        if (!count) {
            count = 10;
        }
        axios.get(`${Config.restaurantApi}/payments/${value}/${count}`, {
            headers: {
                "x-auth-token": JSON.parse(getCookie("restaurantToken"))
            }
        }).then((e) => {
            setAllPayments(e.data);
            setPage(value);
        }).catch(e => console.log(e))

    }
    const handleGetSettings = async () => {
        await axios.get(`${Config.userApi}/settings`).then(res => {
            setSettings(res.data)
            console.log(res.data)
        }).catch(e => console.log(e))
    }
    const handleGetUserById = async (id) => {
        await axios.get(`${Config.adminApi}/users/byId/${id}`, {
            headers: {
                "x-auth-token": JSON.parse(getCookie("adminToken"))
            }
        }).then(res => {
            setUser(res.data)
        }).catch(e => console.log(e))
    }
    const store = {
        getUser:getUser,setUser,handleGetUserById,getServices:getServices,setServices,handleGetAllServices,getAllReports:getAllReports,setAllReports,getAllTickets:getAllTickets,setAllTickets,handleGetAllTickets,getAllProductsPlan:getAllProductsPlan,setAllProductsPlan,getAllPages:getAllPages,setAllPages,getAllCategoriesForSearch:getAllCategoriesForSearch,setAllCategoriesForSearch,handleGetAllCategoriesForEdite,getAllContacts:getAllContacts,setAllContacts,getAllQuestions:getAllQuestions,setAllQuestions,handleLogOutAdmin,getAdmin:getAdmin,setAdmin,handleGetSettings,getSettings:getSettings,setSettings,_isRestaurant,_isAdmin,getCaptchaVerifyed:getCaptchaVerifyed,setCaptchaVerifyed,handleGetAllDeposits,getAllDeposits:getAllDeposits,setAllDeposits,getChoosenLocation:getChoosenLocation,setChoosenLocation,handleGetAllProductsByParent,getAllProducts:getAllProducts,setAllProducts,handleGetAllProducts,handleGetAllCategories,getAllCategories:getAllCategories,setAllCategories,handleRestaurantGetAllCashouts,getMatrixBetweenPoints:getMatrixBetweenPoints,setMatrixBetweenPoints,handleFindAllPayments,getRestaurant:getRestaurant,setRestaurant,handleGetRestaurant,handleRestaurantLogin,setCookie, getCookie, handleLoginAdmin, getAdminUsername: getAdminUsername, setAdminUsername, getAdminPassword: getAdminPassword, setAdminPassword, handleGetAllDiscounts, getAllDiscounts: getAllDiscounts, setAllDiscounts, handleGetAllCashouts, getAllCashouts: getAllCashouts, setAllCashouts, handleGetAllPayments, getAllPayments: getAllPayments, setAllPayments, getAllUsers: getAllUsers, setAllUsers, handleGetAllUsers, getTableLoading: getTableLoading, setTableLoading, getPage: getPage, setPage, getDeleteAlert: getDeleteAlert, setDeleteAlert, getResturantId: getResturantId, setResturantId, getLoading, getLoading, setLoading, collapsed: collapsed, setCollapsed, validator, getAllResturants: getAllResturants, setAllResturants,

    };

    return (
        <MahabadContext.Provider value={store}>
            {props.children}
        </MahabadContext.Provider>
    );
};

export default GlobalState;

