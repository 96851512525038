import React, { useEffect, useContext, useState } from 'react';
import { ClockCircleOutlined, UploadOutlined, FontColorsOutlined, MobileOutlined, UserOutlined, LockOutlined } from '@ant-design/icons';
import { Button, Switch, Upload, Drawer, Form, Input, message, Select, Space } from 'antd';
import Config from '../../Config/config.json';
import axios from 'axios';
import MahabadContext from '../../Context/MainContext';
const { Option } = Select;
const { TextArea } = Input;
let fileList = [
];


const Index = ({ open, onClose, service }) => {
  const context = useContext(MahabadContext)
  const [messageApi, contextHolder] = message.useMessage();
  const [getLoading, setLoading] = useState(false);
  const [getPhoto, setPhoto] = useState("");

  if (service) {
    fileList = [{
      name: service.photo,
      status: 'done',
      url: Config.adminPics + service.photo,
      thumbUrl: Config.adminPics + service.photo,
    }]
  }
  const handleEditeService = async () => {
    setLoading(true);
    context.setTableLoading(true)
    let body = {
      'name': service.name,
      'category': service.category,
      'dollar_price': service.dollar_price,
      'derham_price': service.derham_price,
      'turkish_lir_price': service.turkish_lir_price,
      'description': service.description,
      'photo': service.photo,
      'tax': service.tax,
    }
    await axios.put(`${Config.adminApi}/updateService/${service._id}`, body, {
      headers: {
        "x-auth-token": JSON.parse(context.getCookie("adminToken"))
      }
    }).then(res => {
      messageApi.open({
        type: 'success',
        content: 'سرویس با موفقیت ویرایش شد',
      });
      context.handleGetAllServices()
      setLoading(false);
      context.setTableLoading(false)
      onClose()

    }).catch((err) => {
      setLoading(false);
      context.setTableLoading(false)
      onClose()
      messageApi.open({
        type: 'error',
        content: 'سرویس ویرایش نشد',
      });
    })
  }
  return (
    <>
      {contextHolder}
      <Drawer
        title="ویرایش سرویس"
        onClose={onClose}
        open={open}
        bodyStyle={{
          paddingBottom: 80,
          direction: "rtl"
        }}
        style={{ direction: "rtl" }}
        extra={
          <Space>
            <Button onClick={onClose} danger>انصراف</Button>
            <Button onClick={handleEditeService} type="primary">
              {(getLoading) ? ("در حال ویرایش") : ("ویرایش")}
            </Button>
          </Space>
        }
      >
        
        <Form layout="vertical" hideRequiredMark>
          <p>دسته بندی</p>
          <Select

            defaultValue={service.category}
            style={{ width: "100%" }}
            onChange={(e, v) => {
              if (v.value === "null") {
                service.category = "null"
              } else {
                service.category = v.label
              }
            }}
            options={(context.getAllCategories !== null) ? ([{ "value": "null", "label": "انتخاب کنید" }, ...context.getAllCategories.map(item => ({ "value": item._id, "label": item.name }))]) : []}
          />
          <p>نام سرویس</p>
          <Input onChange={(e) => {
            service.name = e.target.value
            context.validator.current.showMessageFor("name");
          }} prefix={<FontColorsOutlined />} placeholder={service.name} />
          <p>کارمزد سرویس</p>
          <Input onChange={(e) => {
            service.tax = e.target.value
            context.validator.current.showMessageFor("tax");
          }} prefix={<FontColorsOutlined />} placeholder={service.tax} />
          <p>توضیحات سرویس</p>
          <TextArea rows={3} onChange={(e) => {
           service.description = e.target.value
          }} type="text" placeholder={service.description} />
          <p>قیمت به دلار</p>
          <Input onChange={(e) => {
            service.dollar_price = e.target.value
            context.validator.current.showMessageFor("website");
          }} prefix={<FontColorsOutlined />}  placeholder={service.dollar_price} />
          <p>قیمت به درهم</p>
          <Input onChange={(e) => {
            service.derham_price = e.target.value
          }} type="text" prefix={<MobileOutlined />} placeholder={service.derham_price} />
          <p>قیمت به لیر</p>
          <Input onChange={(e) => {
            service.turkish_lir_price = e.target.value
          }} type="text" placeholder={service.turkish_lir_price} />
          <p>عکس سرویس</p>
          <Upload
            action={`${Config.adminApi}/addPhoto`}
            listType="picture"
            defaultFileList={[...fileList]}
            className="upload-list-inline"
            name="logo"
            style={{width:"100%"}}
            headers={{
              "x-auth-token": JSON.parse(context.getCookie("adminToken"))
            }}
            onChange={(e) => { 
              service.photo = e.file.response
             }}
          >
            <Button style={{width:"100%"}} icon={<UploadOutlined />}>عکس سرویس</Button>
          </Upload>

        </Form>
      </Drawer>
    </>
  );
};
export default Index;