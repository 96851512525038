import React, { useContext, useEffect, useState, useRef } from 'react';
import Layout2 from "../Layout/Index"
import { InfoCircleOutlined, UserOutlined, UploadOutlined } from '@ant-design/icons';
import { Tabs, Layout, Breadcrumb, theme, Row, Col, Input, Image, Typography, Tooltip, Button, message, Upload, Alert } from 'antd';
import axios from 'axios';
import Config from '../../Config/config.json';
import Paper from '@mui/material/Paper';
import MahabadContext from '../../Context/MainContext';
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Editor } from '@tinymce/tinymce-react';
const ReactDOMServer = require('react-dom/server');
const HtmlToReactParser = require('html-to-react').Parser;
const { Content } = Layout;

const { TextArea } = Input;
const { Title, Text } = Typography;

const fileList = [
];
function uploadAdapter(loader) {
    return {
        upload: () => {
            return new Promise(async (resolve, reject) => {
                try {
                    const file = await loader.file;
                    const response = await axios.request({
                        method: "POST",
                        url: `${Config.adminApi}/addPhoto`,
                        data: {
                            "logo": file
                        },
                        headers: {
                            "Content-Type": "multipart/form-data"
                        }
                    });
                    resolve({
                        default: `${Config.adminPics}${response.data}`
                    });
                    console.log(response.data)
                } catch (error) {
                    reject("Hello");
                }
            });
        },
        abort: () => { }
    };

}
function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
        return uploadAdapter(loader);
    };
}
const Index = () => {
    const context = useContext(MahabadContext);
    const editorRef = useRef(null);
    const [messageApi, contextHolder] = message.useMessage();
    const [getSettings, setSettings] = useState(null);
    const [loadings, setLoadings] = useState(false);
    const [getItemsList, setItemsList] = useState([]);
    const log = () => {
        if (editorRef.current) {
            console.log(editorRef.current.getContent());
        }
    };
    const handleGetSettings = async () => {
        setLoadings(true)
        await axios.get(`${Config.adminApi}/settings`, {
            headers: {
                "x-auth-token": JSON.parse(context.getCookie("adminToken"))
            }
        }).then(res => {
            setSettings(res.data)
            setLoadings(false)
        }).catch(err => {
            setLoadings(false)
            messageApi.open({
                type: 'error',
                content: "مشکلی در دریافت تنظیمات وجود دارد.",
            });
        })
    }
    const handleUpdateSettings = async () => {
        setLoadings(true)
        await axios.post(`${Config.adminApi}/updateSettings`, (getSettings), {
            headers: {
                "x-auth-token": JSON.parse(context.getCookie("adminToken"))
            }
        }).then(res => {
            setLoadings(false)
            messageApi.open({
                type: 'success',
                content: 'تنظیمات با موفقیت ویرایش شد',
            });
        }).catch(err => {
            setLoadings(false)
            messageApi.open({
                type: 'error',
                content: 'تنظیمات ویرایش نشد',
            });
        })
    }
    const {
        token: { colorBgContainer },
    } = theme.useToken();
    useEffect(() => {
        handleGetSettings()
    }, [])
    return (
        <Layout2>
            {contextHolder}
            <Content
                style={{
                    direction: "rtl"
                }}
            >
                <div
                    style={{
                        marginBottom: 20
                    }}
                >
                    <Breadcrumb
                        style={{
                            margin: '10px 0',
                            direction: "rtl"
                        }}
                    >
                        <Breadcrumb.Item>خانه</Breadcrumb.Item>
                        <Breadcrumb.Item>تنظیمات</Breadcrumb.Item>
                    </Breadcrumb>

                    {(getSettings === null) ? (null) : (<>
                        <Paper style={{ boxShadow: "unset" }}>
                            <Row gutter={[16, 16]} justify="center" >
                                <Col xs={24} sm={24} md={24} lg={20} xl={20}>
                                    <div style={{ backgroundColor: "white", borderRadius: "10px", padding: "20px", marginBottom: "10px" }}>

                                        <div style={{ textAlign: "right" }}>
                                            <Row gutter={[16, 16]}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                                    <Tabs
                                                        size="large"
                                                        tabPosition={"right"}
                                                        items={[
                                                            {
                                                                label: `اصلی`,
                                                                key: 1,
                                                                children: (<Row gutter={[18, 18]}>
                                                                    <Col xs={24} lg={24} md={12} xl={24} sm={24}>
                                                                        <p>نام سایت</p>
                                                                        <Input
                                                                            onChange={(e) => {
                                                                                setSettings({ ...getSettings, siteName: e.target.value })
                                                                            }}
                                                                            value={getSettings.siteName}
                                                                            prefix={<UserOutlined className="site-form-item-icon" />}
                                                                            suffix={
                                                                                <Tooltip title="نام سایت">
                                                                                    <InfoCircleOutlined
                                                                                        style={{
                                                                                            color: 'rgba(0,0,0,.45)',
                                                                                        }}
                                                                                    />
                                                                                </Tooltip>
                                                                            }
                                                                        />
                                                                        <br />
                                                                        <br />
                                                                    </Col>
                                                                    <Col xs={24} lg={24} md={12} xl={24} sm={24}>
                                                                        <p>توضیحات سایت</p>
                                                                        <Input
                                                                            onChange={(e) => {
                                                                                setSettings({ ...getSettings, siteDesc: e.target.value })
                                                                            }}
                                                                            value={getSettings.siteDesc}
                                                                            prefix={<UserOutlined className="site-form-item-icon" />}
                                                                            suffix={
                                                                                <Tooltip title="توضیحات سایت">
                                                                                    <InfoCircleOutlined
                                                                                        style={{
                                                                                            color: 'rgba(0,0,0,.45)',
                                                                                        }}
                                                                                    />
                                                                                </Tooltip>
                                                                            }
                                                                        />
                                                                        <br />
                                                                        <br />
                                                                    </Col>
                                                                    <Col xs={24} lg={24} md={24} xl={24} sm={24}>

                                                                        <Button loading={loadings} onClick={handleUpdateSettings} type="primary" block>ثبت</Button>
                                                                    </Col>
                                                                </Row>),
                                                            },
                                                            {
                                                                label: `قیمت ارزها`,
                                                                key: 2,
                                                                children: (<Row gutter={[18, 18]}>
                                                                    <Col xs={12} lg={12} md={12} xl={12} sm={12}>
                                                                        <Text>قیمت ارز دلار به تومان</Text>
                                                                        <Input
                                                                            onChange={(e) => {
                                                                                setSettings({ ...getSettings, dollar_price: e.target.value })
                                                                            }}
                                                                            value={getSettings.dollar_price}
                                                                            prefix={<UserOutlined className="site-form-item-icon" />}
                                                                            suffix={
                                                                                <Tooltip title="قیمت ارز دلار به تومان">
                                                                                    <InfoCircleOutlined
                                                                                        style={{
                                                                                            color: 'rgba(0,0,0,.45)',
                                                                                        }}
                                                                                    />
                                                                                </Tooltip>
                                                                            }
                                                                        />
                                                                        <br />
                                                                        <br />
                                                                    </Col>
                                                                    <Col xs={12} lg={12} md={12} xl={12} sm={12}>
                                                                        <Text>قیمت ارز درهم به تومان</Text>
                                                                        <Input
                                                                            onChange={(e) => {
                                                                                setSettings({ ...getSettings, derham_price: e.target.value })
                                                                            }}
                                                                            value={getSettings.derham_price}
                                                                            prefix={<UserOutlined className="site-form-item-icon" />}
                                                                            suffix={
                                                                                <Tooltip title="قیمت ارز درهم به تومان">
                                                                                    <InfoCircleOutlined
                                                                                        style={{
                                                                                            color: 'rgba(0,0,0,.45)',
                                                                                        }}
                                                                                    />
                                                                                </Tooltip>
                                                                            }
                                                                        />
                                                                        <br />
                                                                        <br />
                                                                    </Col>
                                                                    <Col xs={12} lg={12} md={12} xl={12} sm={12}>
                                                                        <Text>قیمت ارز لیر ترکیه به تومان</Text>
                                                                        <Input
                                                                            onChange={(e) => {
                                                                                setSettings({ ...getSettings, turkish_lir_price: e.target.value })
                                                                            }}
                                                                            value={getSettings.turkish_lir_price}
                                                                            prefix={<UserOutlined className="site-form-item-icon" />}
                                                                            suffix={
                                                                                <Tooltip title="قیمت ارز لیر ترکیه به تومان">
                                                                                    <InfoCircleOutlined
                                                                                        style={{
                                                                                            color: 'rgba(0,0,0,.45)',
                                                                                        }}
                                                                                    />
                                                                                </Tooltip>
                                                                            }
                                                                        />
                                                                        <br />
                                                                        <br />
                                                                    </Col>
                                                                    <Col xs={24} lg={24} md={24} xl={24} sm={24}>
                                                                        <Button loading={loadings} onClick={handleUpdateSettings} type="primary" block>ثبت</Button>
                                                                    </Col>
                                                                    
                                                                    </Row>),
                                                            },
                                                            {
                                                                label: `آدرس و اطلاعات تماس`,
                                                                key: 3,
                                                                children: (<Row gutter={[18, 18]}>
                                                                    <Col xs={12} lg={12} md={12} xl={12} sm={12}>
                                                                        <Text>شماره تماس با ما</Text>
                                                                        <Input
                                                                            onChange={(e) => {
                                                                                setSettings({ ...getSettings, phone: e.target.value })
                                                                            }}
                                                                            value={getSettings.phone}
                                                                            prefix={<UserOutlined className="site-form-item-icon" />}
                                                                            suffix={
                                                                                <Tooltip title="شماره تماس با ">
                                                                                    <InfoCircleOutlined
                                                                                        style={{
                                                                                            color: 'rgba(0,0,0,.45)',
                                                                                        }}
                                                                                    />
                                                                                </Tooltip>
                                                                            }
                                                                        />
                                                                        <br />
                                                                        <br />
                                                                    </Col>
                                                                    <Col xs={12} lg={12} md={12} xl={12} sm={12}>
                                                                        <Text>لینک اینستاگرام شما</Text>
                                                                        <Input
                                                                            onChange={(e) => {
                                                                                setSettings({ ...getSettings, instagram: e.target.value })
                                                                            }}
                                                                            value={getSettings.instagram}
                                                                            prefix={<UserOutlined className="site-form-item-icon" />}
                                                                            suffix={
                                                                                <Tooltip title="لینک اینستاگرام شما">
                                                                                    <InfoCircleOutlined
                                                                                        style={{
                                                                                            color: 'rgba(0,0,0,.45)',
                                                                                        }}
                                                                                    />
                                                                                </Tooltip>
                                                                            }
                                                                        />
                                                                        <br />
                                                                        <br />
                                                                    </Col>
                                                                    <Col xs={12} lg={12} md={12} xl={12} sm={12}>
                                                                        <Text>لینک تلگرام شما</Text>
                                                                        <Input
                                                                            onChange={(e) => {
                                                                                setSettings({ ...getSettings, telegram: e.target.value })
                                                                            }}
                                                                            value={getSettings.telegram}
                                                                            prefix={<UserOutlined className="site-form-item-icon" />}
                                                                            suffix={
                                                                                <Tooltip title="لینک تلگرام شما">
                                                                                    <InfoCircleOutlined
                                                                                        style={{
                                                                                            color: 'rgba(0,0,0,.45)',
                                                                                        }}
                                                                                    />
                                                                                </Tooltip>
                                                                            }
                                                                        />
                                                                        <br />
                                                                        <br />
                                                                    </Col>
                                                                    <Col xs={12} lg={12} md={12} xl={12} sm={12}>
                                                                        <Text>لینک یوتیوب شما</Text>
                                                                        <Input
                                                                            onChange={(e) => {
                                                                                setSettings({ ...getSettings, youtube: e.target.value })
                                                                            }}
                                                                            value={getSettings.youtube}
                                                                            prefix={<UserOutlined className="site-form-item-icon" />}
                                                                            suffix={
                                                                                <Tooltip title="لینک یوتیوب شما">
                                                                                    <InfoCircleOutlined
                                                                                        style={{
                                                                                            color: 'rgba(0,0,0,.45)',
                                                                                        }}
                                                                                    />
                                                                                </Tooltip>
                                                                            }
                                                                        />
                                                                        <br />
                                                                        <br />
                                                                    </Col>
                                                                    <Col xs={12} lg={12} md={12} xl={12} sm={12}>
                                                                        <Text>لینک لینکدین شما</Text>
                                                                        <Input
                                                                            onChange={(e) => {
                                                                                setSettings({ ...getSettings, linkedin: e.target.value })
                                                                            }}
                                                                            value={getSettings.linkedin}
                                                                            prefix={<UserOutlined className="site-form-item-icon" />}
                                                                            suffix={
                                                                                <Tooltip title="لینک لینکدین شما">
                                                                                    <InfoCircleOutlined
                                                                                        style={{
                                                                                            color: 'rgba(0,0,0,.45)',
                                                                                        }}
                                                                                    />
                                                                                </Tooltip>
                                                                            }
                                                                        />
                                                                        <br />
                                                                        <br />
                                                                    </Col>
                                                                    <Col xs={12} lg={12} md={12} xl={12} sm={12}>
                                                                        <Text>لینک x شما</Text>
                                                                        <Input
                                                                            onChange={(e) => {
                                                                                setSettings({ ...getSettings, x_app: e.target.value })
                                                                            }}
                                                                            value={getSettings.x_app}
                                                                            prefix={<UserOutlined className="site-form-item-icon" />}
                                                                            suffix={
                                                                                <Tooltip title="لینک x شما">
                                                                                    <InfoCircleOutlined
                                                                                        style={{
                                                                                            color: 'rgba(0,0,0,.45)',
                                                                                        }}
                                                                                    />
                                                                                </Tooltip>
                                                                            }
                                                                        />
                                                                        <br />
                                                                        <br />
                                                                    </Col>
                                                                    <Col xs={24} lg={24} md={24} xl={24} sm={24}>
                                                                        <Button loading={loadings} onClick={handleUpdateSettings} type="primary" block>ثبت</Button>
                                                                    </Col>
                                                                    
                                                                    </Row>),
                                                            },
                                                        ]}
                                                    />
                                                </Col>

                                            </Row>

                                        </div>


                                    </div>
                                </Col>
                            </Row>
                        </Paper></>)}

                </div>
            </Content>
        </Layout2>

    );
};
export default Index;