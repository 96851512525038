import * as React from "react";
import * as ReactDOM from "react-dom";
import Categories from './Admin/Categories/Index';
import Services from './Admin/Services/Index';
import Products from './Admin/Products/Index';
import Payments from './Admin/Payments/Index';
import Tickets from './Admin/Tickets/Index';
import Pages from './Admin/Pages/Index';
import CreatePage from './Admin/CreatePage/Index';
import Complaints from './Admin/Complaints/Index';
import ViewReport from './Admin/ViewReport/Index';
import ViewTicket from './Admin/ViewTicket/Index';
import EditePage from './Admin/EditePage/Index';
import Setting from './Admin/Setting/Index';
import Users from './Admin/Users/Index';
import Login from './Admin/Login/Index';


import './index.css';
import GlobalState from "./GlobalState";
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";


const router = createBrowserRouter([
  {
    path: "/users",
    element: <Users />
  },
  {
    path: "/categories",
    element: <Categories />
  },
  {
    path: "/services",
    element: <Services />
  },
  {
    path: "/setting",
    element: <Setting />
  },
  {
    path: "/pages",
    element: <Pages />
  },
  {
    path: "/create",
    element: <CreatePage />
  },
  {
    path: "/edite",
    element: <EditePage />
  },
  {
    path: "/admin/login",
    element: <Login />
  },
  {
    path: "/products",
    element: <Products />
  },
  {
    path: "/tickets",
    element: <Tickets />
  },
  {
    path: "/complaints",
    element: <Complaints />
  },
  {
    path: "/payments",
    element: <Payments />
  },
  {
    path: "/services",
    element: <Products />
  },
  {
    path: "/viewTicket",
    element: <ViewTicket />
  },
  {
    path: "/viewReport",
    element: <ViewReport />
  }
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <GlobalState>
<RouterProvider router={router} />
  </GlobalState>
  
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
