import React, { useEffect, useContext, useState } from 'react';
import { ClockCircleOutlined, UploadOutlined, FontColorsOutlined, MobileOutlined, UserOutlined, LockOutlined } from '@ant-design/icons';
import { Button, Switch, Upload, Drawer, Form, Input, message, Select, Space } from 'antd';
import Config from '../../Config/config.json';
import axios from 'axios';
import MahabadContext from '../../Context/MainContext';
const { Option } = Select;

const fileList = [
];
const Index = ({ open, onClose }) => {
  const context = useContext(MahabadContext)
  const [messageApi, contextHolder] = message.useMessage();
  const [getLoading, setLoading] = useState(false);
  const [getName, setName] = useState('');
  const [getCount, setCount] = useState('');
  const [getPrice, setPrice] = useState('');

  const handleAddProduct = async () => {
    setLoading(true);
    context.setTableLoading(true)
    let body = {
      'name': getName,
      'clicks': getCount,
      'price': getPrice,
    }
    await axios.post(`${Config.adminApi}/products`, body, {
      headers: {
        "x-auth-token": JSON.parse(context.getCookie("adminToken"))
      }
    }).then(res => {
      context.handleGetAllProductsPlans()
      setLoading(false);
      context.setTableLoading(false)
      onClose()
      messageApi.open({
        type: 'success',
        content: 'محصول با موفقیت افزوده شد',
      });
    }).catch((err) => {
      setLoading(false);
      context.setTableLoading(false)
      onClose()
      messageApi.open({
        type: 'error',
        content: 'محصول اضافه نشد',
      });
    })
  }

  return (
    <>
      {contextHolder}
      <Drawer
        title="افزودن محصول اشتراکی"
        onClose={onClose}
        open={open}
        bodyStyle={{
          paddingBottom: 80,
          direction: "rtl"
        }}
        style={{ direction: "rtl" }}
        extra={
          <Space>
            <Button onClick={onClose}>انصراف</Button>
            <Button onClick={handleAddProduct} type="primary">
              {(getLoading) ? ("در حال ثبت") : ("ثبت")}
            </Button>
          </Space>
        }
      >
        <Form layout="vertical" hideRequiredMark>
          <p>نام محصول</p>
          <Input onChange={(e) => {
            setName(e.target.value)
          }} prefix={<FontColorsOutlined />} status={(getName.length < 3) ? ("error") : (null)} placeholder="نام محصول را وارد کنید" />
          <p>تعداد کلیک</p>
          <Input onChange={(e) => {
            setCount(e.target.value)
          }} prefix={<FontColorsOutlined />} status={(getCount.length < 3) ? ("error") : (null)} placeholder="تعداد کلیک محصول را وارد کنید" />
          <p>قیمت محصول</p>
          <Input onChange={(e) => {
            setPrice(e.target.value)
            context.validator.current.showMessageFor("name");
          }} prefix={<FontColorsOutlined />} status={(getPrice.length < 3) ? ("error") : (null)} placeholder="قیمت محصول را وارد کنید" />
          
        </Form>
      </Drawer>
    </>
  );
};
export default Index;