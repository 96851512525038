import React, { useEffect, useContext, useState } from 'react';
import { EyeInvisibleOutlined, EyeTwoTone, LockOutlined } from '@ant-design/icons';
import { Button, Col, Upload, Drawer, Form, Input, message, Select, Space } from 'antd';
import Config from '../../Config/config.json';
import axios from 'axios';
import MahabadContext from '../../Context/MainContext';
const { Option } = Select;

const Index = ({ open1, onClose, product }) => {
  const context = useContext(MahabadContext)
  const [messageApi, contextHolder] = message.useMessage();
  const [getLoading, setLoading] = useState(false);
  const [getPasswordBefore, setPasswordBefore] = useState(null);
  const [getPassword1, setPassword1] = useState(null);
  const [getPassword2, setPassword2] = useState(null);
  const [getLoadings, setLoadings] = useState(false);
  const [passwordVisible, setPasswordVisible] = React.useState(false);

  const handleChangePassword = async() => {
    setLoadings(true)
    let body = {
      "lastpassword" : getPasswordBefore,
      "password1" : getPassword1,
      "password2" : getPassword2,
    }
    await axios.post(`${Config.adminApi}/changePassword`, (body), {
        headers: {
            "x-auth-token": JSON.parse(context.getCookie("adminToken"))
        }
    }).then(res => {
        setLoadings(false)
        messageApi.open({
            type: 'success',
            content: res.data.message,
        });
        onClose()
    }).catch(err => {
        setLoadings(false)
        messageApi.open({
            type: 'error',
            content: err.response.data.message,
        });
    })
  }
  return (
    <>
      {contextHolder}
      <Drawer
        title="ویرایش رمز عبور"
        onClose={onClose}
        open={open1}
        bodyStyle={{
          paddingBottom: 80,
          direction: "rtl"
        }}
        style={{ direction: "rtl" }}
        extra={
          <Space>
            <Button onClick={onClose} danger>انصراف</Button>
            <Button onClick={handleChangePassword} type="primary">
              {(getLoading) ? ("در حال ویرایش") : ("تایید")}
            </Button>
          </Space>
        }
      >
        <Form layout="vertical" hideRequiredMark>
          <p>رمز عبور قبلی</p>
          <Input.Password onChange={(e) => {
              setPasswordBefore(e.target.value)
              context.validator.current.showMessageFor("lastpassword");
            }} status={(getPasswordBefore !== null) ? ((getPasswordBefore.length < 5) ? ("error") : ("null")) : ("error")} placeholder="رمز عبور قبلی خود را وارد کنید" />
          <p>رمز عبور جدید</p>
          <Input.Password onChange={(e) => {
              setPassword1(e.target.value)
              context.validator.current.showMessageFor("lastpassword");
            }} status={(getPassword1 !== getPassword2) ? ("error") : ("null")} placeholder="رمز عبور جدید خود را وارد کنید" />
          <p>تکرار رمز عبور جدید</p>

            <Input.Password onChange={(e) => {
              setPassword2(e.target.value)
              context.validator.current.showMessageFor("lastpassword");
            }} status={(getPassword1 !== getPassword2) ? ("error") : ("null")} placeholder="تکرار رمز عبور جدید خود را وارد کنید" />


        </Form>
      </Drawer>
    </>
  );
};
export default Index;