import React, { useContext ,useEffect } from 'react';
import Captcha from "./Captcha"
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { Layout, Row, Col, theme, Typography, Input, Button, Space } from 'antd';
import MahabadContext from '../../Context/MainContext';
import { useNavigate } from 'react-router-dom';
import Config from '../../Config/config.json';

const Index = () => {
    const context = useContext(MahabadContext)
    const {
        token: { colorBgContainer },
    } = theme.useToken();
    let navigate = useNavigate();
    const checkLogin = async () => {
      let result = await context._isAdmin()
      if (result === true) {
        navigate("/users")
      }
    }
  
    useEffect(() => {
        // context.handleGetSettings()
      checkLogin()
    },[])
    return (
        <Row>
            <Col
                span={24}
                style={{
                    direction: "rtl",
                    backgroundImage: `url(${window.origin + "/Admin/images/bg.jpg"})`,
                    backgroundRepeat: "no-repeat",
                    backgroundColor: "#cccccc",
                    position: "cover",
                    width: "100vw",
                    height: "100vh",
                    backgroundAttachment: "fixed",
                    backgroundPosition: "right"
                }}
            >
                <Row justify="center" align="center">
                <Col xs={22} sm={22} md={22} xl={12} lg={12} style={{ margin: "40px", boxShadow: "0px 0px 20px black", backgroundColor: "#ffffffeb", padding: "25px", borderRadius: "10px", textAlign: "center" }}>
                        <Col xs={24} sm={24} md={24} xl={24} lg={24}>
                        {(context.getSettings !== null) ? ( <img style={{ height: "64px", width: "64px" }} src={Config.adminPics + context.getSettings.logo} />) : ( <img style={{ height: "64px", width: "64px" }} src={window.origin + "/Admin/images/logo.svg"} />)}
                        </Col>
                        <Col xs={24} sm={24} md={24} xl={24} lg={24}>
                            {(context.getSettings !== null) ? (<h1>{context.getSettings.name}</h1>) : (<h1>دیجی فالوور</h1>)}
                            {(context.getSettings !== null) ? (<p>{context.getSettings.description}</p>) : (<p>سامانه ی فروش فالور شبکه های اجتماعی</p>)}
                        </Col>
                        <Col xs={24} sm={24} md={24} xl={24} lg={24} style={{ marginTop: "5px" }}>
                            <h3>ورود</h3>
                        </Col>
                        <Col xs={24} sm={24} md={24} xl={24} lg={24} style={{ textAlign: "right" }}>
                            <span style={{ fontSize: "16px", textAlign: "right" }}>نام کاربری</span>
                            <Input onChange={(e) => {
                                context.setAdminUsername(e.target.value)
                                context.validator.current.showMessageFor("نام کاربری");
                            }} name="نام کاربری" size="large" placeholder="نام کاربری" prefix={<UserOutlined />} />
                            {context.validator.current.message(
                                "نام کاربری",
                                context.getAdminUsername,
                                "required|min:3"
                            )}
                        </Col>
                        <Col xs={24} sm={24} md={24} xl={24} lg={24} style={{ textAlign: "right", marginTop: "5px" }}>
                            <span style={{ fontSize: "16px", textAlign: "right" }}>رمز عبور</span>
                            <Input onChange={(e)=>{
        context.setAdminPassword(e.target.value)
          context.validator.current.showMessageFor("رمز عبور");
    }} type="password" name="رمز عبور" size="large" placeholder="رمز عبور" prefix={<LockOutlined />} />
                                {context.validator.current.message(
                                "رمز عبور",
                                context.getAdminPassword,
                                "required|min:3"
                            )}
                        </Col>
                        <Row justify="center" align="center" style={{ marginTop: "5px"}}>
                        <Captcha />
                        </Row>
                        <Col xs={24} sm={24} md={24} xl={24} lg={24} style={{ marginTop: "5px"}}>
    

                            <Button onClick={()=>{context.handleLoginAdmin()}} type="primary" danger block>
                                ورود
    </Button>
                        </Col>


                    </Col>
                </Row>
            </Col>
        </Row>

    );
};
export default Index;