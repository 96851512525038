import React, { useEffect, useContext, useState } from 'react';
import { ClockCircleOutlined, UploadOutlined, FontColorsOutlined, MobileOutlined, UserOutlined, LockOutlined } from '@ant-design/icons';
import { Button, Col, Upload, Drawer, Form, Input, message, Select, Space } from 'antd';
import Config from '../../Config/config.json';
import axios from 'axios';
import MahabadContext from '../../Context/MainContext';
const { Option } = Select;

const fileList = [
];
const Index = ({ open, onClose }) => {
  const context = useContext(MahabadContext)
  const [messageApi, contextHolder] = message.useMessage();
  const [getLoading, setLoading] = useState(false);
  const [getCategoryName, setCategoryName] = useState("");
  const [topCategory, setTopCategory] = useState(null);
  const [getPhoto, setPhoto] = useState("");
  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };
  const handleAddCategory = async () => {
    setLoading(true);
    context.setTableLoading(true)
    let body = {
      'name': getCategoryName
    }
    await axios.post(`${Config.adminApi}/category`, body, {
      headers: {
        "x-auth-token": JSON.parse(context.getCookie("adminToken"))
      }
    }).then(res => {
      context.handleGetAllCategories()
      setLoading(false);
      context.setTableLoading(false)
      onClose()
      messageApi.open({ 
        type: 'success',
        content: 'دسته بندی با موفقیت افزوده شد',
      });
    }).catch((err) => {
      setLoading(false);
      context.setTableLoading(false)
      onClose()
      messageApi.open({
        type: 'error',
        content: 'دسته بندی اضافه نشد',
      });
    })
  }

  return (
    <>
      {contextHolder}
      <Drawer
        title="افزودن دسته بندی"
        onClose={onClose}
        open={open}
        bodyStyle={{
          paddingBottom: 80,
          direction: "rtl"
        }}
        style={{ direction: "rtl" }}
        extra={
          <Space>
            <Button onClick={onClose}>انصراف</Button>
            <Button onClick={handleAddCategory} type="primary">
              {(getLoading) ? ("در حال ثبت") : ("ثبت")}
            </Button>
          </Space>
        }
      >
        <Form layout="vertical" hideRequiredMark>
          <p>نام دسته بندی</p>
          <Input onChange={(e) => {
            setCategoryName(e.target.value)
            context.validator.current.showMessageFor("name");
          }} prefix={<FontColorsOutlined />} status={(getCategoryName.length < 3) ? ("error") : (null)} placeholder="نام دسته بندی را وارد کنید" />
          {/* <p>دسته بندی پدر</p>
          <Select
          
      defaultValue={"انتخاب کنید"}
      style={{ width: "100%" }}
      onChange={(e,v)=>{
        if(v.value === "null"){
          setTopCategory("null")
        }else{
          setTopCategory(v.label)
        }
      }}
      options={(context.getAllCategoriesForSearch !== null) ? ([{"value" : "null"  , "label" : "انتخاب کنید"},...context.getAllCategoriesForSearch.map(item=> ({"value" : item._id  , "label" : item.name}))]) : []}
    /> */}
          {/* <p>عکس دسته بندی</p>
          <Upload
            action={`${Config.adminApi}/category/addPhoto`}
            listType="picture"
            defaultFileList={[...fileList]}
            className="upload-list-inline"
            name="categoryPhoto"
            headers={{
              "x-auth-token": JSON.parse(context.getCookie("adminToken"))
            }}
            onChange={(e) => { setPhoto(e.file.response) }}
          >
            <Button icon={<UploadOutlined />}>عکس دسته بندی</Button>
          </Upload> */}
        </Form>
      </Drawer>
    </>
  );
};
export default Index; 