import React, { useEffect, useContext, useState } from 'react';
import { EyeOutlined, DeleteOutlined, DownloadOutlined, ApartmentOutlined } from '@ant-design/icons';
import { Button, Tooltip, Modal, Typography, Table, message, Pagination, Image, Col, Row } from 'antd';
import axios from 'axios';
import Config from '../../Config/config.json';
import MahabadContext from '../../Context/MainContext';
import Edite from "./Edite";
import Grid from '@mui/material/Grid';
import { Link } from 'react-router-dom';
const { Text } = Typography;




const Index = () => {
  const context = useContext(MahabadContext)
  const [getTicket, setTicket] = useState(null);
  const [messageApi, contextHolder] = message.useMessage();
  const [getAwnser, setAwnser] = useState(false)
  const [openEdite, setOpenEdite] = useState(false);
  const [openCategories, setOpenCategories] = useState(false);
  const showDrawerEdite = () => {
    setOpenEdite(true);
  };

  const onCloseEdite = () => {
    setOpenEdite(false);
  };
  const onShowCategories = () => {
    setOpenCategories(true);
  };

  const onCloseCategories = () => {
    setOpenCategories(false);
  };
  let columns = [
    {
      title: 'موضوع',
      dataIndex: 'title',
    },
    {
      title: 'پیام',
      dataIndex: 'text',
    },
    {
      title: 'نام فروشگاه',
      dataIndex: 'name',
    },
    {
      title: 'اولویت',
      dataIndex: 'priority',
    },
    {
      title: 'دپارتمان',
      dataIndex: 'departman',
    },
    {
      title: 'وضعیت',
      dataIndex: 'status',
    },
    {
      title: 'مشاهده',
      key: '_id',
      dataIndex: '_id',
      value: '_id',
      fixed: 'right',
      width: 100,
      render: (id) => <Tooltip title="مشاهده تیکت">
        <Link  onClick={(e) => {
          setTicket(context.getAllTickets.find(item => item._id == id))
          context.setCookie("ticket",JSON.stringify(context.getAllTickets.find(item => item._id == id)))
        }} to="/viewTicket">
        <Button  type="primary" shape="circle" icon={<EyeOutlined />}  />
        </Link>

      </Tooltip>,
    },
    {
      title: 'حذف',
      key: '_id',
      dataIndex: '_id',
      value: '_id',
      fixed: 'right',
      width: 100,
      render: (id) => <Tooltip title="حذف تیکت">
        <Button onClick={(e) => {
          setTicket(context.getAllTickets.find(item => item._id == id))
          context.setDeleteAlert(true)
        }} type="primary" danger shape="circle" icon={<DeleteOutlined />} />
      </Tooltip>,
    },
  ];
  const handleDeleteTicket = async () => {
    context.setTableLoading(true)
    await axios.delete(`${Config.adminApi}/deleteTicket/${getTicket._id}`, {
      headers: {
        "x-auth-token": JSON.parse(context.getCookie("adminToken"))
      }
    }).then(res => {
      context.setDeleteAlert(false)
      context.handleGetAllTickets()
      messageApi.open({
        type: 'success',
        content: 'تیکت موفقیت حذف شد',
      });
      context.setTableLoading(false)
    }).catch((err) => {
      context.setDeleteAlert(false)
      context.setTableLoading(false)
      messageApi.open({
        type: 'error',
        content: 'تیکت حذف نشد',
      });
    })
  }
  useEffect(() => {
    context.handleGetAllTickets()
  }, [])
  const tableProps = {
    loading: context.getLoading
  };
  return (
    <>
      <Modal okType="danger" onOk={(e) => {
        handleDeleteTicket()
      }} okText="تایید" cancelText="انصراف" style={{ direction: "rtl" }} title="شما در حال حذف تیکت هستید" open={context.getDeleteAlert} onCancel={() => { context.setDeleteAlert(false) }}>
        <p>آیا مطمئنید؟</p>
      </Modal>
      <div style={{ direction: "rtl" }}>{contextHolder}</div>
      <Table
        columns={columns}
        size="middle"
        tableLayout="auto"
        style={{ direction: "rtl" }}
        scroll={{
          x: 100
        }}
        loading={context.getTableLoading}
        pagination={false}
        dataSource={(context.getAllTickets !== null) ? context.getAllTickets : []}
      />
      <div style={{ marginTop: "15px" }}><Pagination onChange={(e, v) => {
        context.handleGetAllTickets(e)
      }} defaultCurrent={context.getPage}
        total={1000} /></div>
    </>
  );
};
export default Index;