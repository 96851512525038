import React, { useState, useContext , useEffect } from 'react';
import Layout2 from "../Layout/Index"
import { PlusOutlined } from '@ant-design/icons';
import { Layout, Breadcrumb, theme, Button, Row, Col, Input } from 'antd';
import Table from "./Table";
import Add from "./Add";
import axios from 'axios';
import Config from '../../Config/config.json';
import MahabadContext from '../../Context/MainContext';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const { Content } = Layout;

const { Search } = Input;

const Index = () => {
    const [open, setOpen] = useState(false);
    const context = useContext(MahabadContext);
    const [size, setSize] = useState('large'); // default is 'middle'
    const [age, setAge] = React.useState("انتخاب کنید");

    const handleChange = (event) => {
      setAge(event.target.value);
      handleGetCategoryByParent(event.target.value)
    };
  
    const handleGetCategoryByParent = async (name) => {
        context.setTableLoading(true)
        await axios.get(`${Config.adminApi}/category/getByParent/${name}`, {
            headers: {
                "x-auth-token": JSON.parse(context.getCookie("adminToken"))
            }
        }).then(res => {
            context.setAllCategories(res.data)
            context.setTableLoading(false)
        }).catch(ex=>{
            context.handleGetAllCategories()
        })
    }
    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };
    const {
        token: { colorBgContainer },
    } = theme.useToken();
    useEffect(() => {
        context.handleGetAllCategories()
      }, [])
    return (
        <Layout2>

            <Row gutter={[16, 16]} justify="start">
                <Col xs={24} sm={14} md={18} lg={20} xl={20} style={{ display: "flex", alignItems: "center" }}>
                    <Breadcrumb
                        style={{
                            margin: '10px',
                            direction: "rtl"
                        }}
                    >
                        <Breadcrumb.Item>خانه</Breadcrumb.Item>
                        <Breadcrumb.Item>دسته بندی ها</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
                <Col xs={24} sm={10} md={6} lg={4} xl={4} style={{ textAlign: "left" }}>
                    <div>
                        <Button onClick={showDrawer} type="primary" icon={<PlusOutlined />} size={size}>
                            افزودن
                        </Button>
                    </div>
                </Col>
            </Row>
            {/* <Row gutter={[16, 16]} justify="start">
                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "right" }}>
                    <div>
                        <p>مرتب سازی بر اساس دسته بندی پدر</p>
                        <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">دسته بندی پدر</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={age}
          label="Age"
          onChange={handleChange}
        >
            <MenuItem value={"انتخاب کنید"}>{"انتخاب کنید"}</MenuItem>
            {(context.getAllCategoriesForSearch !== null) ? (
                context.getAllCategoriesForSearch.map(item=>
                    <MenuItem value={item.name}>{item.name}</MenuItem>)
            ) : null}
        </Select>
      </FormControl>
                    </div>
                </Col>

            </Row> */}
            <Content
                style={{
                    direction: "rtl"
                }}
            >
                <div
                    style={{
                        marginTop: "20px",
                        minHeight: 360,
                        borderRadius: "25px"
                    }}
                >


                    <Add open={open} onClose={onClose} />

                    <Table />
                </div>
            </Content>
        </Layout2>

    );
};
export default Index;