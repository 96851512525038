import React, { useEffect, useContext, useState } from 'react';
import { ClockCircleOutlined, UploadOutlined, FontColorsOutlined, MobileOutlined, UserOutlined, LockOutlined } from '@ant-design/icons';
import { Button, Switch, Upload, Drawer, Form, Input, message, Select, Space } from 'antd';
import Config from '../../Config/config.json';
import axios from 'axios';
import MahabadContext from '../../Context/MainContext';
const { Option } = Select;
const { TextArea } = Input;
const fileList = [
];
const Index = ({ open, onClose }) => {
  const context = useContext(MahabadContext)
  const [messageApi, contextHolder] = message.useMessage();
  const [getLoading, setLoading] = useState(false);
  const [getPhoto, setPhoto] = useState("");
  const [getName, setName] = useState("");
  const [getCategory, setCategory] = useState("");
  const [getdollar_price, setdollar_price] = useState("");
  const [getderham_price, setderham_price] = useState("");
  const [getturkish_lir_price, setturkish_lir_price] = useState("");
  const [getdescription, setdescription] = useState(false);
  const [getTax, setTax] = useState(null);

  const handleAddService = async () => {
    setLoading(true);
    context.setTableLoading(true)
    let body = {
      'name': getName,
      'category': getCategory,
      'dollar_price': getdollar_price,
      'derham_price': getderham_price,
      'turkish_lir_price': getturkish_lir_price,
      'description': getdescription,
      'photo': getPhoto,
      'tax': getTax,
    }
    await axios.post(`${Config.adminApi}/service`, body, {
      headers: {
        "x-auth-token": JSON.parse(context.getCookie("adminToken"))
      }
    }).then(res => {
      context.handleGetAllServices()
      setLoading(false);
      context.setTableLoading(false)
      onClose()
      messageApi.open({
        type: 'success',
        content: 'سرویس با موفقیت افزوده شد',
      });
    }).catch((err) => {
      setLoading(false);
      context.setTableLoading(false)
      onClose()
      messageApi.open({
        type: 'error',
        content: 'سرویس اضافه نشد',
      });
    })
  }
  return (
    <>
      {contextHolder}
      <Drawer
        title="افزودن سرویس"
        onClose={onClose}
        open={open}
        bodyStyle={{
          paddingBottom: 80,
          direction: "rtl"
        }}
        style={{ direction: "rtl" }}
        extra={
          <Space>
            <Button onClick={onClose}>انصراف</Button>
            <Button onClick={handleAddService} type="primary">
              {(getLoading) ? ("در حال ثبت") : ("ثبت")}
            </Button>
          </Space>
        }
      >
        <Form layout="vertical" hideRequiredMark>
          <p>دسته بندی</p>
          <Select

            defaultValue={"انتخاب کنید"}
            style={{ width: "100%" }}
            onChange={(e, v) => {
              if (v.value === "null") {
                setCategory("null")
              } else {
                setCategory(v.label)
              }
            }}
            options={(context.getAllCategories !== null) ? ([{ "value": "null", "label": "انتخاب کنید" }, ...context.getAllCategories.map(item => ({ "value": item._id, "label": item.name }))]) : []}
          />
          <p>نام سرویس</p>
          <Input onChange={(e) => {
            setName(e.target.value)
            context.validator.current.showMessageFor("name");
          }} prefix={<FontColorsOutlined />} status={(getName.length < 3) ? ("error") : (null)} placeholder="نام سرویس را وارد کنید" />
          <p>کارمزد سرویس</p>
          <Input onChange={(e) => {
            setTax(e.target.value)
            context.validator.current.showMessageFor("tax");
          }} prefix={<FontColorsOutlined />} placeholder="کارمزد سرویس را به درصد وارد کنید" />
          <p>توضیحات سرویس</p>
          <TextArea rows={3} onChange={(e) => {
            setdescription(e.target.value)
          }} type="text" status={(getdescription.length > 5) ? (null) : ("error")} placeholder="توضیحات سرویس را وارد کنید" />
          <p>قیمت به دلار</p>
          <Input onChange={(e) => {
            setdollar_price(e.target.value)
            context.validator.current.showMessageFor("website");
          }} prefix={<FontColorsOutlined />} status={(getdollar_price.length < 1) ? ("error") : (null)} placeholder="قیمت سرویس را به دلار وارد کنید" />
          <p>قیمت به درهم</p>
          <Input onChange={(e) => {
            setderham_price(e.target.value)
          }} type="text" prefix={<MobileOutlined />} status={(getderham_price.length > 1) ? (null) : ("error")} placeholder="قیمت سرویس را به درهم وارد کنید" />
          <p>قیمت به لیر</p>
          <Input onChange={(e) => {
            setturkish_lir_price(e.target.value)
          }} type="text" status={(getturkish_lir_price.length > 1) ? (null) : ("error")} placeholder="قیمت سرویس را به لیر ترکیه وارد کنید" />
          <p>عکس سرویس</p>
          <Upload
            action={`${Config.adminApi}/addPhoto`}
            listType="picture"
            defaultFileList={[...fileList]}
            className="upload-list-inline"
            name="logo"
            style={{width:"100%"}}
            headers={{
              "x-auth-token": JSON.parse(context.getCookie("adminToken"))
            }}
            onChange={(e) => { setPhoto(e.file.response) }}
          >
            <Button style={{width:"100%"}} icon={<UploadOutlined />}>عکس سرویس</Button>
          </Upload>

        </Form>
      </Drawer>
    </>
  );
};
export default Index;