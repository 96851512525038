import React, { useState,useContext , useEffect } from 'react';
import Layout2 from "../Layout/Index"
import { PlusOutlined } from '@ant-design/icons';
import { Layout, Breadcrumb, theme, Button, Row, Col, Input , message , Table } from 'antd';
import axios from 'axios';
import Config from '../../Config/config.json';
import MahabadContext from '../../Context/MainContext';
import { Grid, IconButton } from '@mui/material';
const { Content } = Layout;

const { Search } = Input;

const Index = () => {
    const [open, setOpen] = useState(false);
    const context = useContext(MahabadContext);
    const [size, setSize] = useState('large'); // default is 'middle'
    const [getTicket, setTicket] = useState(null); // default is 'middle'
    const [getText, setText] = useState(null); // default is 'middle'
    const [getAwnser, setAwnser] = useState(false); // default is 'middle'
    const [messageApi, contextHolder] = message.useMessage();
    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };
    const {
        token: { colorBgContainer },
    } = theme.useToken();
    const handleReplayTicket = async (value, count) => {
        let body = {
             "text": getText
           }
           await axios.post(`${Config.adminApi}/replayToTicket/${getTicket._id}`, body, {
             headers: {
               "x-auth-token": JSON.parse(context.getCookie("adminToken"))
             }
           }).then(res => {
               context.setCookie("ticket",JSON.stringify(res.data))
             setTicket(res.data)
             messageApi.open({
                type: 'success',
                content: "پاسخ تیکت با موفقیت افزوده شد",
              });
           }).catch(err => {
            messageApi.open({
                type: 'error',
                content: "مشکلی در ارسال پاسخ به تیکت وجود دارد",
              });
           })
       }
       let columns = [
        {
          title: 'موضوع',
          dataIndex: 'title',
        },
        {
          title: 'پیام',
          dataIndex: 'text',
        },
        {
          title: 'نام فروشگاه',
          dataIndex: 'name',
        },
        {
          title: 'اولویت',
          dataIndex: 'priority',
        },
        {
          title: 'وضعیت',
          dataIndex: 'status',
        },
        {
          title: 'دپارتمان',
          dataIndex: 'departman',
        }
      ];
       useEffect(() => {
        setTicket(JSON.parse(context.getCookie("ticket")))
        context.handleGetUserById(JSON.parse(context.getCookie("ticket")).userId)
      }, [])
    return (
        <Layout2>
                             <Row gutter={[16, 16]} justify="space-between">
                             <Col xs={24} sm={7} md={10} lg={12} xl={14}>
                             <Breadcrumb
                    style={{
                        margin: '16px 0',
                        direction: "rtl"
                    }}
                >
                    <Breadcrumb.Item>خانه</Breadcrumb.Item>
                    <Breadcrumb.Item>تیکت ها</Breadcrumb.Item>
                </Breadcrumb>
                        </Col>
                    </Row>
            <Content
                style={{
                    marginTop: '20px',
                }}
            >
     
                <div
                    style={{
                        marginBottom: 20
                    }}
                >
                    {context.getUser !== null ? (<section>
          <Grid container spacing={2} alignItems="start" justifyContent="flex-end">
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12} >
              <Table
        columns={columns}
        size="middle"
        tableLayout="auto"
        style={{ direction: "rtl" }}
        scroll={{
          x: 100
        }}
        loading={context.getTableLoading}
        pagination={false}
        dataSource={(getTicket !== null) ? ([getTicket]) : []}
      />
              <IconButton className="butSo" onClick={() => {
                setAwnser(!getAwnser)
              }}>
                <Grid container spacing={2} alignItems="center" justifyContent="space-between">
                  <Grid item xl={6} lg={6} md={6} sm={6} xs={6} className='d-flex text-right' style={{textAlign:"right"}}>
                    پاسخ
                  </Grid>
                  <Grid item xl={6} lg={6} md={6} sm={6} xs={6} style={{ display: "flex", justifyContent: "end" }}>
                    {(typeof window !== "undefined") ? (<img style={{ height: "20px", width: "20px", textAlign: "left" }} src={window.origin + "/Admin/images/arrowButtom.svg"} />) : (null)}
                  </Grid>
                </Grid>
              </IconButton>


              {getAwnser ? (<>
                <Grid flexDirection={"row"} alignItems={"center"}>
                  <hr />
                  <Grid container spacing={2} alignItems="start" justifyContent="center">
                    {/* <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  ارسال تیکت
                </Grid> */}
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                      <Grid container spacing={2} alignItems="start" justifyContent="center">
                        {/* <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                          <p>نام</p>
                          <input onChange={(e) => {
                            setSubject(e.target.value)
                            validator.current.showMessageFor("نام کاربری");
                          }} className='ticketInput' value={JSON.parse(getCookie("store")).name} disabled style={{textAlign:"center"}} />
                        </Grid> */}

                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                          <p>پیام شما</p>
                          <textarea onChange={(e) => {
                            setText(e.target.value)
                            context.validator.current.showMessageFor("پیام شما");
                          }} rows={5} className='awnserTextarea' placeholder='موضوع تیکت را وارد کنید' />
                          {context.validator.current.message("پیام شما", getText, 'required|min:3')}
                        </Grid>
                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                          <Button onClick={() => {
                            handleReplayTicket()
                          }} className='btnSic w-100 mb-3'>ارسال</Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </>) : (null)}


              {getTicket !== null ? (getTicket.replays.sort((a, b) =>  new Date(b.createdDate) - new Date(a.createdDate)).map(item=>
                <Grid container  alignItems="start" justifyContent="flex-end">
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12} >
                  <Grid container  alignItems="center" justifyContent="space-between" className='ViewTicketBox'>
                    <Grid item xl={6} lg={6} md={6} sm={6} xs={6} >
                      <Grid container  alignItems="start" justifyContent="flex-end">
                        <Grid item xl={2} lg={2} md={2} sm={2} xs={2} >
                        {(item.role === "Admin") ? ((typeof window !== "undefined") ? (<img
                            style={{ width: "96px", height: "96px",borderRadius:"100px" }}
                            src={window.origin + "/Admin/images/Admin.svg"}
                            alt={"عکس ادمین"}
                          />) : (null)) : (!context.getUser.logo ? (null) : ((typeof window !== "undefined") ? (<img
                            style={{ width: "96px", height: "96px",borderRadius:"100px" }}
                            src={Config.adminPics + context.getUser.logo}
                          />) : (null)))}
                          
                        </Grid>
                        <Grid item xl={10} lg={10} md={10} sm={10} xs={10} >
                        <p>{(item.role === "Admin") ? (<span>پشتیبانی</span>) : (<span>{item.name}</span>)}</p>
                          {(item.role === "Admin") ? (<span className="op1">اپراتور</span>) : (<span className="op">کاربر</span>)}
                        </Grid>
                      </Grid>


                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={6} xs={6} style={{ textAlign: "left" }}>
                      {new Date(item.createdDate).toLocaleTimeString('fa-IR')} | {new Date(item.createdDate).toLocaleDateString('fa-IR')}
                    </Grid>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12} style={{marginTop:"16px"}}>
                    <hr className='w-100' />
                    </Grid>
                    <pre style={{ whiteSpace: "normal", fontFamily: "iransans" }}>
                      {`${item.text}`}
                    </pre>
                  </Grid>
                </Grid>
              </Grid>
                )) : (null)}
              {getTicket !== null ? (<Grid container alignItems="start" justifyContent="flex-end">
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12} >
                  <Grid container alignItems="center" justifyContent="space-between" className='ViewTicketBox'>
                    <Grid item xl={6} lg={6} md={6} sm={6} xs={6} >
                      <Grid container alignItems="start" justifyContent="flex-end">
                        <Grid item xl={2} lg={2} md={2} sm={2} xs={2} >
                          {!context.getUser.logo ? (null) : ((typeof window !== "undefined") ? (<img
                            style={{ width: "96px", height: "96px",borderRadius:"100px" }}
                            src={Config.adminPics + context.getUser.logo}
                            alt={context.getUser.name}
                          />) : (null))}
                        </Grid>
                        <Grid item xl={10} lg={10} md={10} sm={10} xs={10} >
                          <p>{getTicket.name}</p>
                          <span className="op">کاربر</span>
                        </Grid>
                      </Grid>


                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={6} xs={6} style={{ textAlign: "left" }}>
                      {new Date(getTicket.createdDate).toLocaleTimeString('fa-IR')} | {new Date(getTicket.createdDate).toLocaleDateString('fa-IR')}
                    </Grid>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12} style={{marginTop:"16px"}}>
                    <hr className='w-100' />
                    </Grid>
                    
                    <pre style={{ whiteSpace: "normal", fontFamily: "iransans" }}>
                      {`${getTicket.text}`}
                    </pre>
                  </Grid>
                </Grid>
              </Grid>) : (null)}




            </Grid>
          </Grid>

        </section>) : (null)} 
        
                </div>
            </Content>
        </Layout2>

    );
};
export default Index;