import React, { useState, useContext, useEffect, useRef } from 'react';
import Layout2 from "../Layout/Index"
import { PlusOutlined, UploadOutlined, FontColorsOutlined } from '@ant-design/icons';
import { Layout, Breadcrumb, theme, Button, Row, Col, Input, message, Upload, Select } from 'antd';
import axios from 'axios';
import Config from '../../Config/config.json';
import MahabadContext from '../../Context/MainContext';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2';
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Box from '@mui/material/Box';

import Paper from '@mui/material/Paper';
const { Content } = Layout;

const { Search } = Input;


function uploadAdapter(loader) {
    return {
        upload: () => {
            return new Promise(async (resolve, reject) => {
                try {
                    const file = await loader.file;
                    const response = await axios.request({
                        method: "POST",
                        url: `${Config.adminApi}/addPhoto`,
                        data: {
                            "logo": file
                        },
                        headers: {
                            "Content-Type": "multipart/form-data"
                        }
                    });
                    resolve({
                        default: `${Config.adminPics}${response.data}`
                    });
                    console.log(response.data)
                } catch (error) {
                    reject("Hello");
                }
            });
        },
        abort: () => { }
    };

}
function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
        return uploadAdapter(loader);
    };
}
const Index = () => {
    const context = useContext(MahabadContext);
    const [open, setOpen] = useState(false);
    const [messageApi, contextHolder] = message.useMessage();
    const [getLoading, setLoading] = useState(false);
    const [page, setPage] = useState(null);
    const [fileList, setFileList] = useState([])
    const [getSelectedCategory, setSelectedCategory] = useState("null");
    const [getPropertyName, setPropertyName] = useState("")
    const [getPropertyDesc, setPropertyDesc] = useState("")
    const [getProperties, setProperties] = useState([])

    const handleEditePage = async () => {
        const urlSearchString = window.location.search;

        const params = new URLSearchParams(urlSearchString);
        setLoading(true);
        context.setTableLoading(true)
        let body = {
            'h1': page.h1,
            'description': page.description,
            'searchedCategory': page.searchedCategory,
            'url': page.url,
            'photo': page.photo,
            'body': page.body,
            'status': page.status,
            'information': page.information,
            'category': page.category,
        }
        await axios.put(`${Config.adminApi}/pageUpdate/${params.get("id")}`, body, {
            headers: {
                "x-auth-token": JSON.parse(context.getCookie("adminToken"))
            }
        }).then(res => {
            context.handleGetAllResturants()
            setLoading(false);
            context.setTableLoading(false)
            onClose()
            messageApi.open({
                type: 'success',
                content: 'پیج با موفقیت ویرایش شد',
            });
        }).catch((err) => {
            setLoading(false);
            context.setTableLoading(false)
            onClose()
            messageApi.open({
                type: 'error',
                content: 'پیج ویرایش نشد',
            });
        })
    }

    const showDrawer = () => {
        setOpen(true);
    };
    const handleGetPage = async (id) => {
        await axios.get(`${Config.adminApi}/getPage/${id}`, {
            headers: {
                "x-auth-token": JSON.parse(context.getCookie("adminToken"))
            }
        }).then(res => {
            setPage(res.data)
            console.log(res.data.category)
            setFileList([{
                uid: res.data._id,
                name: res.data.photo,
                status: 'done',
                url: `${Config.adminPics}${res.data.photo}`,
            }])
        }).catch((err) => {
            messageApi.open({
                type: 'error',
                content: 'پیج بارگذاری نشد',
            });
        })
    }
    const onClose = () => {
        setOpen(false);
    };
    const {
        token: { colorBgContainer },
    } = theme.useToken();
    useEffect(() => {
        const urlSearchString = window.location.search;

        const params = new URLSearchParams(urlSearchString);
        console.log(params.get("id"))
        context.handleGetAllCategories()
        handleGetPage(params.get("id"))
    }, [])
    return (
        <Layout2>
            {contextHolder}
            <Row gutter={[16, 16]} justify="space-between">
                <Col xs={24} sm={7} md={10} lg={12} xl={14}>
                    <Breadcrumb
                        style={{
                            margin: '16px 0',
                            direction: "rtl"
                        }}
                    >
                        <Breadcrumb.Item>خانه</Breadcrumb.Item>
                        <Breadcrumb.Item>افزودن صفحه</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>

                {/* <Col xs={24} sm={13} md={10} lg={9} xl={7}>
                            <div style={{ marginTop: "10px", marginBottom: "10px" }}>
                                <Search
                                    placeholder="مشخصات کسب و کار را وارد کنید"
                                    allowClear
                                    enterButton="جستجو"
                                    size="large"
                                    onSearch={onSearch}
                                />
                            </div>
                        </Col> */}
            </Row>
            <Content
                style={{
                    marginTop: '20px',
                }}
            >
                <Container maxWidth="xl">
                    <Box sx={{ flexGrow: 1 }}>
                        <Grid container spacing={2}>
                            <Grid xs={12}>
                                <p>URL پیج</p>
                                <Input onChange={(e) => {
                                    setPage(prevState => ({
                                        ...prevState,
                                        url: e.target.value.replace(/\s/g, '_'),
                                    }));
                                    context.validator.current.showMessageFor("url");
                                }} prefix={<FontColorsOutlined />} value={(!page) ? "" : (page.url)} />
                            </Grid>
                            <Grid xs={4}>
                                <p>عنوان پیج</p>
                                <Input onChange={(e) => {
                                    setPage(prevState => ({
                                        ...prevState,
                                        h1: e.target.value,
                                    }));
                                    context.validator.current.showMessageFor("h1");
                                }} prefix={<FontColorsOutlined />} value={(!page) ? "" : (page.h1)} />
                            </Grid>
                            <Grid xs={4}>
                                <p>توضیحات پیج</p>
                                <Input onChange={(e) => {
                                    setPage(prevState => ({
                                        ...prevState,
                                        description: e.target.value,
                                    }));
                                    context.validator.current.showMessageFor("description");
                                }} prefix={<FontColorsOutlined />} value={(!page) ? "" : (page.description)} />
                            </Grid>
                            <Grid xs={4}>

                                <p>کلمه ی کلیدی برای سرویس های قابل فروش</p>
                                <Input onChange={(e) => {
                                    setPage(prevState => ({
                                        ...prevState,
                                        searchedCategory: e.target.value,
                                    }));
                                    context.validator.current.showMessageFor("categories");
                                }} prefix={<FontColorsOutlined />} value={(!page) ? "" : (page.searchedCategory)} />
                            </Grid>
                            <Grid xs={8}>
                                <CKEditor
                                    config={{
                                        // @ts-ignore
                                        extraPlugins: [uploadPlugin]
                                    }}
                                    editor={ClassicEditor}
                                    onReady={(editor) => { }}
                                    onBlur={(event, editor) => { }}
                                    onFocus={(event, editor) => { }}
                                    onChange={(event, editor) => {
                                        setPage(prevState => ({
                                            ...prevState,
                                            body: editor.getData(),
                                        }));
                                    }}
                                    data={(!page) ? "" : (page.body)}
                                />
                            </Grid>
                            <Grid xs={4}>
                                <p>دسته بندی</p>
                                {page ? (<Select
                                    defaultValue={page.category}
                                    style={{ width: "100%" }}
                                    onChange={(e, v) => {
                                        setPage(prevState => ({
                                            ...prevState,
                                            category: v.label
                                        }));
                                    }}
                                    options={[{ value: "null", label: "انتخاب کنید" }, ...context.getAllCategories.map(item => ({ value: item.name, label: item.name }))]}
                                />) : (null)}

                                <p>وضعیت انتشار</p>
                                <Select

                                    defaultValue={"منتشر نشده"}
                                    style={{ width: "100%" }}
                                    onChange={(e, v) => {
                                        if (v.value === "null") {
                                            setPage(prevState => ({
                                                ...prevState,
                                                status: "null"
                                            }));
                                        } else {
                                            setPage(prevState => ({
                                                ...prevState,
                                                status: v.label
                                            }));
                                        }
                                    }}
                                    options={[{ value: "null", label: "انتخاب کنید" }, { value: "منتشر نشده", label: "منتشر نشده" }, { value: "منتشر شده", label: "منتشر شده" }]}
                                />
                                <p>عکس بالای صفحه</p>
                                <Upload
                                    action={`${Config.adminApi}/addPhoto`}
                                    listType="picture"
                                    defaultFileList={[...fileList]}
                                    className="upload-list-inline"
                                    name="logo"
                                    headers={{
                                        "x-auth-token": JSON.parse(context.getCookie("adminToken"))
                                    }}
                                    onChange={(e) => {
                                        setPage(prevState => ({
                                            ...prevState,
                                            photo: e.file.response
                                        }));
                                        setFileList([{
                                            uid: "-1",
                                            name: e.file.response,
                                            status: 'done',
                                            url: `${Config.adminPics}${e.file.response}`,
                                        }])
                                    }}
                                >
                                    <Button icon={<UploadOutlined />}>عکس هدر</Button>
                                </Upload>
                                <Grid xs={12} container spacing={2} justifyContent={"center"} alignItems={"center"}>
                                    {(fileList.length >= 1) ? (fileList.map(item =>

                                        <Grid xs={2} alignSelf={"end"}>
                                            <img style={{ width: "100%", height: "300px", marginTop: "10px" }} src={item.url} />

                                        </Grid>
                                    )) : (null)}
                                </Grid>
                            </Grid>
                            <Grid xs={12} container spacing={2} justifyContent={"center"} alignItems={"center"}>
                                <Grid xs={5}>
                                    <p>نام ویژگی</p>
                                    <Input onChange={(e) => {
                                        setPropertyName(e.target.value)
                                        context.validator.current.showMessageFor("name");
                                    }} prefix={<FontColorsOutlined />} status={(getPropertyName.length < 3) ? ("error") : (null)} placeholder="نام ویژگی را وارد کنید" />
                                </Grid>
                                <Grid xs={5}>
                                    <p>توضیحات ویژگی</p>
                                    <Input onChange={(e) => {
                                        setPropertyDesc(e.target.value)
                                        context.validator.current.showMessageFor("name");
                                    }} prefix={<FontColorsOutlined />} status={(getPropertyDesc.length < 3) ? ("error") : (null)} placeholder="توضیحات ویژگی را وارد کنید" />
                                </Grid>
                                <Grid xs={2} alignSelf={"end"}>
                                    <Button style={{ width: "100%", backgroundColor: "green" }} onClick={() => {
                                        setPage(prevState => ({
                                            ...prevState,
                                            information: [...page.information, { name: getPropertyName, description: getPropertyDesc }]
                                        }));
                                        setProperties([...getProperties, { name: getPropertyName, description: getPropertyDesc }])
                                    }} type="primary" icon={<PlusOutlined />} size={"medium"}>
                                        افزودن ویژگی
                                    </Button>
                                </Grid>
                            </Grid>
                            {(!page) ? (null) : ((!page.information) ? (null) : (page.information.map(item => (<Grid className="property" xs={12} container spacing={2} justifyContent={"center"} alignItems={"center"}>
                                <Grid xs={5}>
                                    <p>نام ویژگی</p>
                                    <Input onChange={(e) => {
                                        item.name = e.target.value
                                        context.validator.current.showMessageFor("name");
                                    }} prefix={<FontColorsOutlined />} disabled value={item.name} />
                                </Grid>
                                <Grid xs={5}>
                                    <p>توضیحات ویژگی</p>
                                    <Input onChange={(e) => {
                                        item.description = e.target.value
                                        context.validator.current.showMessageFor("description");
                                    }} prefix={<FontColorsOutlined />} disabled value={item.description} />
                                </Grid>
                                <Grid xs={2} alignSelf={"end"}>
                                    <Button style={{ width: "100%", backgroundColor: "red" }} onClick={() => {
                                        setPage(prevState => ({
                                            ...prevState,
                                            information: page.information.filter(pro => pro.name !== item.name)
                                        }));
                                    }} type="primary" icon={<PlusOutlined />} size={"medium"}>
                                        حذف ویژگی
                                    </Button>
                                </Grid>
                            </Grid>))))}

                            <Grid xs={2}>
                                <div style={{ marginTop: "10px", marginBottom: "10px" }}>
                                    <Button onClick={handleEditePage} type="primary" icon={<PlusOutlined />} size={"large"}>
                                        ویرایش
                                    </Button>
                                </div>
                            </Grid>
                        </Grid>
                    </Box>

                </Container>
            </Content>
        </Layout2>

    );
};
export default Index;